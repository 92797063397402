import { useEffect, useState, useCallback } from "react";
import { Dimensions, ScaledSize } from "react-native";

function getDimensions() {
  return Dimensions.get("screen");
}

export type Orientation = "landscape" | "portrait";

const getOrientation = ({
  width,
  height,
}: {
  width: number;
  height: number;
}): Orientation => (width <= height ? "portrait" : "landscape");

export function useMyDeviceOrientation(): Orientation {
  const [orientation, setOrientation] = useState(getOrientation(getDimensions()));

  const onChange = useCallback(({ screen: scr }: { screen: ScaledSize }) => {
    setOrientation(getOrientation(scr));
  }, []);

  useEffect(() => {
    Dimensions.addEventListener("change", onChange);

    return () => {
      Dimensions.removeEventListener("change", onChange);
    };
  }, [onChange]);

  return orientation;
}

export default useMyDeviceOrientation;