import React from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity, ScrollView } from 'react-native';
import LobbyServiceContext from '../../services/lobby-service';
import axios from 'axios';
import { LOBBY_SERVICE_URL } from "../../constants";
import { styles as GlobalStyles, BACKGROUND_COLOR, DISCONNECTED_COLOR } from "../../styles/styles";
import { createHands } from "../lobby-page/lobby-page";
import { Game, Player, PlayerType } from "../../data/interfaces";
import { readPlayerFromStorage } from "../../services/player-service";
import { showAlert } from "../alert/alert";
import { Avatar, Badge, Icon } from 'react-native-elements';
import {
    Menu,
    MenuOptions,
    MenuOption,
    MenuTrigger,
} from 'react-native-popup-menu';
import { useMyDeviceOrientation } from "../../services/device-orientation";
import { Audio } from 'expo-av';
import { removeDepartedPlayers, insertArrayAtIndex, getStatusText, canFindNumbersInSet, removeCardsFromHand, canUserPlay, areSetsEqual, removeIntersectKeepDuplicates, findIndiciesOfTaxCards, findFindCardInSelectedCards } from "../../utility/utility";

import TelemetryServiceContext from "../../services/telemetry-service";
import ExpoConstants from 'expo-constants';
import Button from '../button/button';

const card1 = require("../../assets/cards/1.png");
const card2 = require("../../assets/cards/2.png");
const card3 = require("../../assets/cards/3.png");
const card4 = require("../../assets/cards/4.png");
const card5 = require("../../assets/cards/5.png");
const card6 = require("../../assets/cards/6.png");
const card7 = require("../../assets/cards/7.png");
const card8 = require("../../assets/cards/8.png");
const card9 = require("../../assets/cards/9.png");
const card10 = require("../../assets/cards/10.png");
const card11 = require("../../assets/cards/11.png");
const card12 = require("../../assets/cards/12.png");
const card13 = require("../../assets/cards/13.png");
const cardVisuals = [card1, card2, card3, card4, card5, card6, card7, card8, card9, card10, card11, card12, card13];

interface IGamePageProps {
    navigation: any;
    route: any;
}

interface IStackProps {
    cards?: number[];
    isLandscape?: boolean;
}

export const GamePage = ({ route }: IGamePageProps) => {
    const joinCode = route && route.params && route.params.joinCode;
    const [player, setPlayer] = React.useState(route && route.params && route.params.player);
    const [sound, setSound] = React.useState({} as any);
    const { telemetryService } = React.useContext(TelemetryServiceContext);
    const [game, setGame] = React.useState({} as Game);
    const [errorString, setErrorString] = React.useState("");
    const { lobbyService } = React.useContext(LobbyServiceContext);
    const [selectedCards, setSelectedCards] = React.useState([] as number[][]);
    const landscape = useMyDeviceOrientation() === "landscape";

    const onGameUpdateEvent = (sender: any, game: Game) => {
        console.log(`Game updated by ${sender.displayName}: ${JSON.stringify(game)}`);
        if (game.activePlayer && (game.activePlayer === player.id)) {
            yourTurnSound();
        } else {
            gameUpdateSound();
        }
        setGame(game);
    };

    const onJoinGameEvent = (sender: any, game: Game) => {
        console.log(`New player joined or left game: ${sender && sender.displayName}`);
        setGame(game);
    };

    const unsubscribeFromJoinGameEvents = () => {
        lobbyService.off('joinGame', onJoinGameEvent);
    };

    const unsubscribeFromGameUpdateEvents = () => {
        lobbyService.off('gameUpdate', onGameUpdateEvent);
    };

    const onPlayCards = () => {
        console.log(`Player ${player.displayName} played cards: ${JSON.stringify(selectedCards)}.`);
        const gameCopy = Object.assign({}, game);
        const selectedCardValues = selectedCards.map(card => card[0]);
        gameCopy.stack = selectedCardValues;
        gameCopy.statusText = getStatusText(player.displayName, selectedCardValues);
        const currPlayerIndex = gameCopy.players.findIndex(p => p.id === player.id);
        gameCopy.players[currPlayerIndex].hand = removeCardsFromHand(selectedCardValues, gameCopy.players[currPlayerIndex].hand);
        gameCopy.lastPlayed = player.id;
        gameCopy.passList = [];
        gameCopy.activePlayer = getNextPlayerId(currPlayerIndex, gameCopy.players);
        if (gameCopy.players[currPlayerIndex].hand?.length == 0) {
            if (!gameCopy.finishOrderIndex) {
                gameCopy.players[currPlayerIndex].finishOrder = 0;
                gameCopy.finishOrderIndex = 1;
            } else {
                gameCopy.players[currPlayerIndex].finishOrder = gameCopy.finishOrderIndex;
                gameCopy.finishOrderIndex = gameCopy.finishOrderIndex + 1;
            }
            console.log(`Player ${player.displayName} finished in order: ${gameCopy.players[currPlayerIndex].finishOrder}`);
        }
        if (isGameOver(gameCopy)) {
            const lastPlayer = getLastPlayerFinished(game);
            if (lastPlayer) {
                lastPlayer.finishOrder = gameCopy.players.length - 1;
            }
        }
        if (!gameCopy.history) {
            gameCopy.history = [gameCopy.stack];
        } else {
            gameCopy.history.push(gameCopy.stack);
        }
        setGame(gameCopy);
        gameUpdateSound();
        setSelectedCards([]);
        lobbyService.emit("gameUpdate", player, gameCopy);
    };

    const gameUpdateSound = async () => {
        /* @info */ const { sound } = await Audio.Sound.createAsync(
          /* @end */ require('../../assets/sounds/card-flip.mp3')
    );
        setSound(sound);

        await /* @info */ sound.playAsync(); /* @end */
    }

    const yourTurnSound = async () => {
        /* @info */ const { sound } = await Audio.Sound.createAsync(
          /* @end */ require('../../assets/sounds/ding.mp3')
    );
        setSound(sound);

        await /* @info */ sound.playAsync(); /* @end */
    }

    const subscribeToJoinGameEvents = React.useCallback(() => {
        lobbyService.on('joinGame', onJoinGameEvent);
    }, [joinCode]);

    const subscribeToGameUpdateEvents = React.useCallback(() => {
        lobbyService.on('gameUpdate', onGameUpdateEvent);
    }, [joinCode]);

    React.useEffect(() => {
        subscribeToGameUpdateEvents();
        subscribeToJoinGameEvents();
        return () => {
            unsubscribeFromGameUpdateEvents();
            unsubscribeFromJoinGameEvents();
        }
    }, []);

    React.useEffect(() => {
        readPlayerFromStorage().then(p => {
            setPlayer(p);
            // const mockGame = require("./mock-game-over.json");
            // setGame(mockGame);
            // return;
            axios.get(`${LOBBY_SERVICE_URL}/api/game/${joinCode}`).then(response => {
                const game = response.data;
                console.log(`Game with joinCode ${game.joinCode} received`);
                setGame(game);
                telemetryService && telemetryService.trackEvent({ name: 'game_page_view', properties: { gameOwner: game.owner, gameId: game.id, joinCode: game.joinCode, playerId: player && player.id, displayName: player && player.displayName, clientVersion: ExpoConstants && ExpoConstants.manifest && ExpoConstants.manifest.version, deviceId: ExpoConstants && ExpoConstants.deviceId } });
            }).catch(error => {
                let errorString = "";
                let status;
                if (error.response && error.response.status === 404) {
                    status = error.response.status;
                    errorString = `Sorry, it looks like the game for join code ${joinCode.toUpperCase()} was not found :(. Swipe back and try another join code.`;
                    setErrorString(errorString);
                } else {
                    errorString = `Sorry, there was an issue joining the game for join code "${joinCode.toUpperCase()}". Double check your join code and try again.`;
                    setErrorString(errorString);
                }
                telemetryService && telemetryService.trackEvent({ name: 'error_page_view', properties: { statusCode: status, error: errorString, playerId: player && player.id, displayName: player && player.displayName, clientVersion: ExpoConstants && ExpoConstants.manifest && ExpoConstants.manifest.version, deviceId: ExpoConstants && ExpoConstants.deviceId } });
                console.error(error.message);
            });
        });
    }, [joinCode]);

    React.useEffect(() => {
        return sound
            ? () => {
              /* @info Always unload the Sound after using it to prevent memory leaks.*/ sound.unloadAsync && sound.unloadAsync(); /* @end */
            }
            : undefined;
    }, [sound]);

    const onPayTax = () => {
        const selectedCardValues = selectedCards.map(card => card[0]);
        console.log(`Player ${player.displayName} paid taxes: ${JSON.stringify(selectedCardValues)}.`);
        const gameCopy = Object.assign({}, game);
        gameCopy.areTaxesInProgress = true;
        gameCopy.statusText = `${player.displayName} paid taxes of ${selectedCardValues.length} card${selectedCardValues.length === 1 ? "" : "s"}`;
        const currPlayerIndex = findCurrentPlayerInGameIndex(player.id, game);
        gameCopy.players[currPlayerIndex].hand = removeCardsFromHand(selectedCardValues, gameCopy.players[currPlayerIndex].hand);
        gameCopy.players[currPlayerIndex].hasPaidTaxes = true;
        const indexOfTaxReceiver = findIndexOfTaxReceiver(currPlayerIndex, game);
        gameCopy.players[indexOfTaxReceiver].hand!.push(...selectedCardValues);
        gameCopy.players[indexOfTaxReceiver].receivedTaxes = selectedCardValues;
        gameCopy.isTaxSession = !isTaxSessionOver(gameCopy);
        if (gameCopy.isTaxSession === false) {
            gameCopy.activePlayer = gameCopy.players[0].id;
            gameCopy.statusText = "All taxes have been paid!";
        }
        setGame(gameCopy);
        setSelectedCards([]);
        lobbyService.emit("gameUpdate", player, gameCopy);
    };

    const onPass = (targetedPlayer?: Player) => {
        const gameCopy = Object.assign({}, game);
        pass(player, gameCopy);
        setGame(gameCopy);
        lobbyService.emit("gameUpdate", targetedPlayer || player, gameCopy);
    };

    const pass = (targetedPlayer: Player, game: Game) => {
        console.log(`Player ${targetedPlayer.displayName} passed their turn.`);
        const currPlayerIndex = game.players.findIndex(p => p.id === targetedPlayer.id);
        game.activePlayer = getNextPlayerId(currPlayerIndex, game.players);
        if (!game.passList || game.passList.length === 0) {
            game.passList = [targetedPlayer.id];
        } else {
            game.passList.push(targetedPlayer.id);
        }
    }

    const onSelectedCardsUpdate = (card: number, index: number, add: boolean) => {
        let newSelectedCards = selectedCards.slice();
        if (add) {
            newSelectedCards.push([card, index]);
        } else {
            const foundCardIndex = findFindCardInSelectedCards(index, newSelectedCards);
            if (foundCardIndex > -1) {
                newSelectedCards.splice(foundCardIndex, 1);
            }
        }
        setSelectedCards(newSelectedCards);
    }

    const onClearStack = () => {
        console.log(`Player ${player.displayName} cleared stack.`);
        const gameCopy = Object.assign({}, game);
        gameCopy.stack = [];
        gameCopy.statusText = `${player.displayName} cleared the stack`;
        gameCopy.passList = [];
        setGame(gameCopy);
        setSelectedCards([]);
        lobbyService.emit("gameUpdate", player, gameCopy);
    }

    const onDeclareRebellion = () => {
        const declaration = `${player.displayName} declared a rebellion! Switch order!`;
        const gameCopy = Object.assign({}, game);
        gameCopy.isTaxSession = false;
        gameCopy.areTaxesInProgres = false;
        gameCopy.statusText = declaration;
        gameCopy.players = gameCopy.players.reverse();
        gameCopy.activePlayer = gameCopy.players[0].id;
        telemetryService && telemetryService.trackEvent({ name: 'rebellion_declared', properties: { gameId: gameCopy.id, declarerId: player.id, declarerDisplayName: player.displayName, winner: gameCopy.players && gameCopy.players[0] && gameCopy.players[0].displayName, loser: gameCopy.players && gameCopy.players[gameCopy.players.length - 1] && gameCopy.players[gameCopy.players.length - 1].displayName, joinCode: gameCopy.joinCode, playerId: player && player.id, displayName: player && player.displayName, clientVersion: ExpoConstants && ExpoConstants.manifest && ExpoConstants.manifest.version, deviceId: ExpoConstants && ExpoConstants.deviceId } });
        setGame(gameCopy);
        lobbyService.emit("gameUpdate", player, gameCopy);
    }

    const onDeclareNoTaxes = () => {
        const declaration = `${player.displayName} declared no taxes!`;
        const gameCopy = Object.assign({}, game);
        gameCopy.isTaxSession = false;
        gameCopy.statusText = declaration;
        gameCopy.activePlayer = gameCopy.players[0].id;
        setPlayersWhoPayTaxes(gameCopy, true);
        telemetryService && telemetryService.trackEvent({ name: 'no_taxes_declared', properties: { gameId: gameCopy.id, declarerId: player.id, declarerDisplayName: player.displayName, winner: gameCopy.players && gameCopy.players[0] && gameCopy.players[0].displayName, loser: gameCopy.players && gameCopy.players[gameCopy.players.length - 1] && gameCopy.players[gameCopy.players.length - 1].displayName, joinCode: gameCopy.joinCode, playerId: player && player.id, displayName: player && player.displayName, clientVersion: ExpoConstants && ExpoConstants.manifest && ExpoConstants.manifest.version, deviceId: ExpoConstants && ExpoConstants.deviceId } });
        setGame(gameCopy);
        lobbyService.emit("gameUpdate", player, gameCopy);
    }

    const onPlayAgainButtonPress = () => {
        const gameCopy = Object.assign({}, game);
        gameCopy.players.sort((a: any, b: any) => {
            if (a.finishOrder === undefined) {
                a.finishOrder = game.players.length - 1;
            }
            if (b.finishOrder === undefined) {
                b.finishOrder = game.players.length - 1;
            }
            return a.finishOrder - b.finishOrder;
        });
        const finishOrder = gameCopy.players.map((player, index) => ({ playerId: player.id, displayName: player.displayName, finishOrder: index }));
        telemetryService && telemetryService.trackEvent({ name: 'end_game', properties: { gameSize: game.players.length, gameId: gameCopy.id, finishOrder: finishOrder && JSON.stringify(finishOrder), winner: gameCopy.players && gameCopy.players[0] && gameCopy.players[0].displayName, loser: gameCopy.players && gameCopy.players[gameCopy.players.length - 1] && gameCopy.players[gameCopy.players.length - 1].displayName, joinCode: gameCopy.joinCode, playerId: player && player.id, displayName: player && player.displayName, clientVersion: ExpoConstants && ExpoConstants.manifest && ExpoConstants.manifest.version, deviceId: ExpoConstants && ExpoConstants.deviceId } });
        removeDepartedPlayers(gameCopy);
        if (gameCopy.players.length >= 4) {
            gameCopy.isTaxSession = true;
            gameCopy.statusText = 'Taxes are in progress';
            setPlayersWhoPayTaxes(gameCopy, false);
            gameCopy.activePlayer = "";
        }
        const middleIndex = Math.floor(gameCopy.players.length / 2);
        if (game.spectators) {
            gameCopy.players = insertArrayAtIndex(gameCopy.spectators, gameCopy.players, middleIndex);
            gameCopy.players = updateFinishOrders(gameCopy.players, middleIndex, gameCopy.spectators.length);
        }
        gameCopy.spectators = [];
        gameCopy.players = createHands(gameCopy.players);
        clearReceivedTaxes(gameCopy);
        gameCopy.stack = [];
        gameCopy.history = [];
        if (!gameCopy.isTaxSession) {
            gameCopy.activePlayer = gameCopy.players[0].id;
            gameCopy.statusText = `${gameCopy.players[0].displayName}'s turn`;
        }
        gameCopy.areTaxesInProgress = false;
        gameCopy.passList = [];
        gameCopy.finishOrderIndex = 0;
        setGame(gameCopy);
        setSelectedCards([]);
        lobbyService.emit("gameUpdate", player, gameCopy);
    };

    const onLeaveGame = () => {
        console.log(`${player.displayName} left the game`);
        const gameCopy = Object.assign({}, game);
        const currentPlayer = findCurrentPlayerInGame(player.id, gameCopy);
        if (currentPlayer) {
            currentPlayer.hasLeft = true;
            setGame(gameCopy);
            lobbyService.emit("gameUpdate", player, gameCopy);
        } else {
            console.error(`Couldn't find player ${player.displayName} in game`);
        }
    };

    const onRejoinGame = () => {
        console.log(`${player.displayName} rejoined the game`);
        const gameCopy = Object.assign({}, game);
        const currentPlayer = findCurrentPlayerInGame(player.id, gameCopy);
        if (currentPlayer) {
            currentPlayer.hasLeft = false;
            setGame(gameCopy);
            lobbyService.emit("gameUpdate", player, gameCopy);
        } else {
            console.error(`Couldn't find player ${player.displayName} in game`);
        }
    }

    const onRemovePlayer = (p: Player) => {
        console.log(`${p.displayName} left the game`);
        const gameCopy = Object.assign({}, game);
        const currentPlayer = findCurrentPlayerInGame(p.id, gameCopy);
        if (currentPlayer) {
            currentPlayer.hasLeft = true;
            if (gameCopy.activePlayer === currentPlayer.id) {
                pass(currentPlayer, gameCopy);
            };
            setGame(gameCopy);
            lobbyService.emit("gameUpdate", p, gameCopy);
        } else {
            console.error(`Couldn't find player ${p.displayName} in game`);
        }
    }

    const hasPlayerLeft = () => {
        const currentPlayer = findCurrentPlayerInGame(player.id, game);
        if (currentPlayer) {
            return !!currentPlayer.hasLeft;
        }
        return true;
    }

    const renderHandButtons = () => {
        if (game && game.players) {
            return (
                <HandButtons
                    nativeID="hand-buttons"
                    onDeclareNoTaxes={onDeclareNoTaxes}
                    onDeclareRebellion={onDeclareRebellion}
                    onLeaveGame={onLeaveGame}
                    canPlayerDeclareNoTaxes={canPlayerDeclareNoTaxes(player, game)}
                    canPlayerDeclareRebellion={canPlayerDeclareRebellion(player, game)}
                    onRejoinGame={onRejoinGame}
                    hasPlayerLeft={hasPlayerLeft()}
                    isTaxSession={game.isTaxSession}
                    canPayTax={canUserPayTax(player.id, selectedCards, game)}
                    onPlayAgainButtonPress={onPlayAgainButtonPress}
                    isGameOver={isGameOver(game)}
                    isLandscape={landscape}
                    canClear={canClearStack(player, game)}
                    onClearStack={onClearStack}
                    canPlay={canUserPlay(player.id, game.activePlayer, game.stack, selectedCards)}
                    onPlayCards={onPlayCards}
                    onPayTax={onPayTax}
                    canPass={canPlayerPass(player, game)}
                    onPass={onPass} />
            );
        }
    }

    const renderGameView = () => {
        return (
            <View nativeID="background-view" style={styles.background}>
                <View nativeID="game-container-view" style={styles.gameContainer}>
                    <View nativeID="game-header-view" style={[landscape && styles.headerContainerLandscape]}>
                        <GameHeader nativeID="game-header-component" game={game} player={player} onRemovePlayer={onRemovePlayer} />
                        {landscape && renderHandButtons()}
                    </View>
                    <View nativeID="bottom-half" style={{ height: '100%', backgroundColor: BACKGROUND_COLOR }}>
                        <View nativeID="stack-view">
                            <StatusBox nativeID="status-box" isLandscape={landscape} status={game.statusText} />
                            <CardStack nativeID="card-stack" isLandscape={landscape} cards={game.stack} />
                        </View>
                        {!landscape && renderHandButtons()}
                        <Hand nativeID="hand-view"
                            hasPaidTaxes={hasPlayerPaidTaxes(player.id, game)}
                            isLandscape={landscape}
                            selectedCards={selectedCards}
                            cards={getPlayersHand(player.id, game)}
                            receivedTaxes={getPlayersReceivedTaxes(player.id, game)}
                            onSelectedCardsUpdate={onSelectedCardsUpdate} />
                    </View>
                </View>
            </View>);
    };


    return (
        errorString && errorString !== "" ?
            <ErrorPage errorString={errorString} /> : renderGameView()
    );
}

const StatusBox = ({ status, isLandscape }: any) => {
    return (
        <View style={[isLandscape ? styles.statusBoxContainerLandscape : styles.statusBoxContainer]}>
            <Text>{status}</Text>
        </View>
    );
}

const HandButtons = ({ onLeaveGame, onRejoinGame, hasPlayerLeft, canPlayerDeclareNoTaxes, canPlayerDeclareRebellion, onDeclareNoTaxes, onDeclareRebellion, isTaxSession, canPayTax, onPayTax, canPlay, canClear, onClearStack, onPlayCards, canPass, onPass, isLandscape, isGameOver, onPlayAgainButtonPress }: any) => {
    if (hasPlayerLeft) {
        return (
            <View style={[isLandscape ? styles.playHandContainerLandscape : styles.playHandContainer]}>
                <Button primary onPress={() => onRejoinGame()} text="Rejoin" buttonStyleOverrides={styles.handButtons} />
            </View>
        );
    }

    if (isTaxSession) {
        return (
            <View style={[isLandscape ? styles.playHandContainerLandscape : styles.playHandContainer]}>
                <Button primary disabled={!canPayTax} onPress={onPayTax} text="Pay Tax" buttonStyleOverrides={styles.handButtons} />
                {(canPlayerDeclareNoTaxes || canPlayerDeclareRebellion) && <Button color="#fafad2" buttonStyleOverrides={styles.handButtons} onPress={canPlayerDeclareRebellion ? onDeclareRebellion : onDeclareNoTaxes} primary text={canPlayerDeclareRebellion ? "Rebellion!" : "No Taxes!"} />}
            </View>);
    }

    if (isGameOver) {
        return (
            <View style={[isLandscape ? styles.playHandContainerLandscape : styles.playHandContainer]}>
                <Button primary onPress={() => onPlayAgainButtonPress()} text="Play again" buttonStyleOverrides={styles.handButtons} />
                <Button secondary color="red" onPress={() => onLeaveGame()} text="Leave game" buttonStyleOverrides={styles.handButtons} />
            </View>
        )
    }
    return (
        <View style={[isLandscape ? styles.playHandContainerLandscape : styles.playHandContainer]}>
            <View style={[!canPlay ? styles.disabledButton : null]}>
                <TouchableOpacity
                    disabled={!canPlay}
                    style={[GlobalStyles.primaryButton, isLandscape ? styles.handButtons : styles.handButtons]}
                    onPress={() => onPlayCards()}
                >
                    <Text style={[GlobalStyles.primaryButtonText]}>{"Play"}</Text>
                </TouchableOpacity>
            </View>
            <View style={[!canPass ? styles.disabledButton : null]}>
                <TouchableOpacity
                    disabled={!canPass}
                    style={[GlobalStyles.primaryButton, isLandscape ? styles.handButtons : styles.handButtons]}
                    onPress={() => canClear ? onClearStack() : onPass()}
                >
                    <Text style={[GlobalStyles.primaryButtonText]}>{canClear ? "Clear" : "Pass"}</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
}

const Hand = ({ cards, receivedTaxes, hasPaidTaxes, onSelectedCardsUpdate, selectedCards, isLandscape }: any) => {
    const positionStyle = (index: number) => {
        return { left: index === 0 ? 5 : index * -60 };
    }

    let filteredCards = Object.assign([], cards);
    let indiciesOfTaxCards: number[] = [];
    if (!hasPaidTaxes) {
        removeIntersectKeepDuplicates(filteredCards, receivedTaxes);
    }
    filteredCards && filteredCards.sort((a: number, b: number) => a - b);
    if (hasPaidTaxes) {
        indiciesOfTaxCards = findIndiciesOfTaxCards(receivedTaxes, filteredCards);
    }
    const maxWidth = filteredCards.length * 32 + 65;
    return (
        <ScrollView nativeID="scroll-view" horizontal style={styles.scrollView}>
            <View nativeID="hand-content-view" style={[styles.handContentView, { maxWidth }]}>
                {filteredCards && filteredCards.map((card: any, index: any): any => <Card key={index} indiciesOfTaxCards={indiciesOfTaxCards} selectedCards={selectedCards} value={card} index={index} rotate={false} positionStyle={positionStyle(index)} onSelectedCardsUpdate={onSelectedCardsUpdate} />)}
            </View>
        </ScrollView>
    );
}

const CardStack = ({ cards, isLandscape }: any) => {
    let rotate = true;
    if (cards?.length === 1) {
        rotate = false;
    }
    return (
        <View nativeID="card-stack-view" style={[isLandscape ? styles.cardStackContainerLandscape : styles.cardStackContainer]}>
            {cards && cards.map((card: any, index: any) => <Card nativeID="card-view" isLandscape={isLandscape} key={index} value={card} index={index} rotate={rotate} isStack={true} />)}
        </View>
    );
}

const Card = ({ value, indiciesOfTaxCards, index, rotate, isStack, positionStyle, onSelectedCardsUpdate, selectedCards }: any) => {
    if (!isStack) {
        const onSelected = (value: number, index: number) => {
            const cardInSelectedSet = findFindCardInSelectedCards(index, selectedCards);
            if (cardInSelectedSet === -1) {
                onSelectedCardsUpdate(value, index, true);
            } else {
                onSelectedCardsUpdate(value, index, false);
            }
        }
        return (
            <TouchableOpacity activeOpacity={1} onPress={() => onSelected(value, index)} style={[indiciesOfTaxCards.includes(index) ? styles.taxCards : null, positionStyle, findFindCardInSelectedCards(index, selectedCards) > -1 ? { bottom: 90 } : null]}>
                <Image
                    style={[styles.cardImage]}
                    source={cardVisuals[value - 1]}
                />
            </TouchableOpacity>);
    }
    let rotationStyle = undefined;
    const getRotation = (index: number,) => {
        let sign = "";
        if (index % 2 !== 0) {
            rotationStyle = styles.rotateRight;
            sign = "-";
        }
        const degree = 10 + (index * 10);
        return { transform: [{ rotate: `${sign}${degree}deg` }] };
    }
    if (rotate) {
        rotationStyle = getRotation(index);
    }
    return (
        <View style={styles.cardStackItem}>
            <Image
                style={[styles.cardImage, rotationStyle]}
                source={cardVisuals[value - 1]}
            />
        </View>
    );
}

const GameHeader = ({ game, player, onRemovePlayer }: any) => {
    let playerList = [];
    if (game && game.players) {
        if (game.spectators) {
            playerList = game.players.concat(game.spectators);
        } else {
            playerList = game.players
        }
    }

    return (
        <View style={styles.headerContainer}>
            {playerList.map((p: Player) => {
                return (
                    <Menu onSelect={value => {
                        if (value === 2) {
                            showAlert(
                                "Remove user",
                                `Are you sure you want to remove ${p.displayName} from the game?`,
                                [
                                    {
                                        text: "No",
                                        onPress: () => console.log("Cancel Pressed"),
                                        style: "cancel"
                                    },
                                    { text: "Remove", onPress: () => onRemovePlayer(p) }
                                ],
                                { cancelable: false }
                            );

                        }
                    }}>
                        <MenuTrigger>
                            <PlayerIcon key={p.id} active={p.id === game.activePlayer || (game.isTaxSession && p.hasPaidTaxes === false)} player={p} done={p.hand ? p.hand.length === 0 : false} />
                        </MenuTrigger>
                        <MenuOptions customStyles={{ optionsContainer: { backgroundColor: 'red', borderRadius: 6, width: 100, height: 30, marginTop: 45, marginLeft: 20 } }}>
                            <MenuOption style={{ flexDirection: 'row', alignItems: 'center', width: 'auto' }} value={2}>
                                <Image style={styles.removeIcon} source={require('../../assets/icons/remove-icon.png')}></Image>
                                <Text style={{ color: 'white', paddingTop: 0, paddingLeft: 4 }}>Remove</Text>
                            </MenuOption>
                        </MenuOptions>
                    </Menu>
                )
            })}

        </View>
    );
}

const PlayerIcon = ({ player, active, done }: any) => {
    return (
        <View style={styles.playerContainer}>
            <Text style={styles.playerHandCount}>{player.hand ? player.hand.length : 0}</Text>
            <Avatar size={30} containerStyle={[player.disconnected ? styles.avatarDisconnected : styles.avatarConnected, done && !player.disconnected ? styles.avatarSuccess : null, !player.hand && styles.avatarSpectator, player.hasLeft && styles.avatarLeftGame]} avatarStyle={player.type === PlayerType.BOT ? styles.avatarBot : {}} rounded title={player.displayName.charAt(0).toUpperCase()}>
            </Avatar>
            {active && <Badge containerStyle={styles.badgeContainer} badgeStyle={[styles.badgeActive]} />}
        </View>
    );
}

const ErrorPage = ({ errorString }: any) => {
    return (
        <View style={styles.inner}>
            <Text style={GlobalStyles.header}>{errorString}</Text>
        </View>
    );
}

// ======================= Helpers ================ //
const canClearStack = (player: any, game: Game) => {
    if (!game.stack) {
        return false;
    }
    if (game.lastPlayed === player.id && game.stack.length > 0) {
        return true;
    }
    if (game.lastPlayed !== player.id && game.stack.length > 0) {

        if (game.passList && game.passList.length > 0 && player.id === game.passList[0]) {
            return true;
        }
    }
    return false;
};

const getPlayersHand = (playerId: string, game: Game): number[] => {
    if (!game || !game.players) {
        return [];
    }

    const player = game.players.find(p => p.id === playerId);
    if (player) {
        return player.hand!;
    }
    return [];
};

const hasPlayerPaidTaxes = (playerId: string, game: Game): boolean => {
    if (!game || !game.players) {
        return false;
    }

    const player = findCurrentPlayerInGame(playerId, game);
    return player?.hasPaidTaxes!;
};

const getPlayersReceivedTaxes = (playerId: string, game: Game): number[] => {
    if (!game || !game.players) {
        return [];
    }

    const player = game.players.find(p => p.id === playerId);
    if (player) {
        return player.receivedTaxes!;
    }
    return [];
};

const findCurrentPlayerInGame = (playerId: string, game: Game) => {
    return game && game.players && game.players.find(p => p.id === playerId) || game && game.spectators && game.spectators.find(p => p.id === playerId);
};

const getLastPlayerInGame = (game: Game) => {
    return game && game.players && game.players[game.players.length - 1];
};

const findCurrentPlayerInGameIndex = (playerId: string, game: Game) => {
    return game && game.players && game.players.findIndex(p => p.id === playerId);
};

const canUserPayTax = (currentPlayerId: string, selectedCards: number[][], game: Game) => {
    const currentPlayer = findCurrentPlayerInGame(currentPlayerId, game);
    if (!currentPlayer) {
        return false;
    }

    if (!currentPlayer.hasPaidTaxes === false) {
        return false;
    }

    const playerFinishOrder = currentPlayer.finishOrder!;
    const last = game.players.length - 1;
    const secondLast = game.players.length - 2;
    const taxablePlayers = [0, 1, secondLast, last];

    if (!taxablePlayers.includes(playerFinishOrder)) {
        return false;
    }

    const currentPlayersHand = currentPlayer.hand!.sort((a, b) => a - b);
    let filteredCards = Object.assign([], currentPlayersHand);
    if (!currentPlayer.hasPaidTaxes && currentPlayer.receivedTaxes) {
        removeIntersectKeepDuplicates(filteredCards, currentPlayer.receivedTaxes);
    }
    filteredCards && filteredCards.sort((a: number, b: number) => a - b);
    if (playerFinishOrder === last) {
        if (selectedCards.length !== 2) {
            return false;
        }
        const sortedSelectedCards = selectedCards.sort((a: number[], b: number[]) => a[0] - b[0]);
        const bottomTwoCardsInHand = filteredCards.slice(0, 2);
        return areSetsEqual(sortedSelectedCards, bottomTwoCardsInHand);
    }
    if (playerFinishOrder === secondLast) {
        if (selectedCards.length !== 1) {
            return false;
        }
        const selectedCard = selectedCards[0][0];
        const bottomCard = filteredCards[0];
        return selectedCard === bottomCard;
    }

    if (playerFinishOrder == 0) {
        if (selectedCards.length !== 2) {
            return false;
        }
    }

    if (playerFinishOrder == 1) {
        if (selectedCards.length !== 1) {
            return false;
        }
    }
    return true;
};

const canPlayerPass = (player: any, game: Game): boolean => {
    if (!player || !game) {
        return false;
    }

    if (player.id === game.activePlayer) {
        if (game.stack && game.stack.length > 0) {
            return true;
        }
    }
    return false;
};

const setPlayersWhoPayTaxes = (game: Game, hasPaidTaxes: boolean) => {
    if (game && game.players && game.players.length > 2) {
        game.players[0].hasPaidTaxes = hasPaidTaxes;
        game.players[1].hasPaidTaxes = hasPaidTaxes;
        game.players[game.players.length - 1].hasPaidTaxes = hasPaidTaxes;
        game.players[game.players.length - 2].hasPaidTaxes = hasPaidTaxes;
    }
};

const clearReceivedTaxes = (game: Game) => {
    if (game && game.players && game.players.length > 0) {
        game.players.forEach(player => {
            player.receivedTaxes = [];
        })
    }
};

const findIndexOfTaxReceiver = (taxeeIndex: number, game: Game): number => {
    switch (taxeeIndex) {
        case 0:
            return game.players.length - 1;
        case 1:
            return game.players.length - 2;
        case (game.players.length - 2):
            return 1;
        case (game.players.length - 1):
            return 0;
    }
    return 0;
};

const getLastPlayerFinished = (game: Game) => {
    if (game && game.players) {
        const foundPlayer = game.players.find(p => p.hand!.length > 0);
        console.log(`Last player is ${foundPlayer?.displayName}`);
        return foundPlayer;
    }
};

const isGameOver = (game: Game): boolean => {
    if (game && game.players) {
        const activePlayers = game.players.filter(player => !player.hasLeft);
        return game.finishOrderIndex >= activePlayers.length - 1;
    }
    return false;
};

const isTaxSessionOver = (game: Game): boolean => {
    if (!game || !game.players) {
        return true;
    }

    const playerNotPaidTaxes = game.players.findIndex(p => p.hasPaidTaxes === false);
    return playerNotPaidTaxes === -1;
};

const getNextPlayerId = (startingIndex: number, players: any): string => {
    let currentIndex = (startingIndex + 1) % players.length;
    while (startingIndex !== currentIndex) {
        if (players[currentIndex].hand && players[currentIndex].hand.length > 0 && !players[currentIndex].hasLeft) {
            return players[currentIndex].id;
        }
        currentIndex = (currentIndex + 1) % players.length;
    }
    return players[startingIndex].id;
};

const canPlayerDeclareRebellion = (player: any, game: Game): boolean => {
    if (!game.isTaxSession || game.areTaxesInProgress) {
        return false;
    }

    const lastPlayer = getLastPlayerInGame(game);
    if (lastPlayer.id !== player.id) {
        return false;
    }
    const hand = getPlayersHand(player.id, game);
    if (canFindNumbersInSet([13, 13], hand)) {
        return true;
    }
    return false;
}

const canPlayerDeclareNoTaxes = (player: any, game: Game): boolean => {
    if (!game.isTaxSession || game.areTaxesInProgress) {
        return false;
    }

    const hand = getPlayersHand(player.id, game);
    if (canFindNumbersInSet([13, 13], hand)) {
        const lastPlayer = getLastPlayerInGame(game);
        if (lastPlayer.id !== player.id) {
            return true;
        }
    }
    return false;
}

const updateFinishOrders = (players: any[], midpoint: number, offset: number) => {
    if (players && players.length > 0 && offset > 0) {
        players.forEach(player => {
            if (player.finishOrder >= midpoint) {
                player.finishOrder = player.finishOrder + offset;
            }
        });
    }
    return players;
};

const styles = StyleSheet.create({
    background: {
        flex: 1,
        backgroundColor: BACKGROUND_COLOR
    },
    gameContainer: {
        flexDirection: 'column',
        height: '100%'
    },
    headerContainer: {
        paddingLeft: 15,
        flexDirection: 'row'
    },
    headerContainerLandscape: {
        height: 140,
        flexDirection: 'row'
    },
    playerContainer: {
        marginRight: 10
    },
    playerHandCount: {
        textAlign: 'center',
        marginBottom: 4
    },
    avatarConnected: {
        backgroundColor: 'black'
    },
    avatarDisconnected: {
        backgroundColor: DISCONNECTED_COLOR
    },
    avatarBot: {
        borderRadius: 30,
        borderWidth: 2,
        borderColor: 'yellow'
    },
    avatarSuccess: {
        backgroundColor: 'green'
    },
    avatarSpectator: {
        backgroundColor: 'orange'
    },
    avatarLeftGame: {
        backgroundColor: 'red'
    },
    badgeContainer: {
        marginTop: 8
    },
    badgeActive: {
        backgroundColor: 'white'
    },
    badgeCurrentPlayer: {
        backgroundColor: 'green'
    },
    statusBoxContainer: {
        marginTop: 50,
        alignItems: 'center'
    },
    statusBoxContainerLandscape: {
        alignItems: 'center'
    },
    cardStackContainer: {
        alignItems: 'center',
        marginTop: 15
    },
    cardStackContainerLandscape: {
        alignItems: 'center',
        marginTop: 20,
        height: 150
    },
    scrollView: {
        position: 'absolute',
        bottom: 0,
        height: 300,
    },
    handContentView: {
        flex: 1,
        bottom: -90,
        flexDirection: 'row',
        position: 'relative',
        minWidth: 200
    },
    cardStackItem: {
        position: 'absolute'
    },
    cardImage: {
        height: 146.5,
        width: 91
    },
    removeIcon: {
        width: 20,
        height: 20
    },
    rotateLeft: {
        transform: [{ rotate: '-10deg' }]
    },
    rotateRight: {
        transform: [{ rotate: '10deg' }]
    },
    inner: {
        padding: 24,
        flex: 1,
        backgroundColor: '#9b854d'
    },
    joinCodeContainer: {
        alignItems: 'center'
    },
    joinCodeText: {
        fontSize: 40
    },
    lobbyContainer: {
        paddingLeft: 30
    },
    playerText: {
        fontSize: 30,
        paddingTop: 15
    },
    playHandContainer: {
        flex: 1,
        marginTop: 280,
        zIndex: 4,
        alignItems: 'center',
        maxHeight: 20
    },
    playHandContainerLandscape: {
        flexDirection: 'column',
        zIndex: 14,
        position: 'absolute',
        right: 15,
        top: 5
    },
    handButtons: {
        width: 200,
        marginBottom: 15
    },
    handButtonsLandscape: {
        width: 200,
        marginRight: 15,
    },
    disabledButton: {
        opacity: 0.3
    },
    taxCards: {
        shadowOpacity: 0.65,
        shadowRadius: 2,
        shadowColor: 'yellow',
        shadowOffset: { height: 0, width: -2.5 },
    },
    playerDisconnectedText: {
        color: '#BFBFB8'
    },
    startButtonContainer: {
        position: 'absolute',
        alignItems: 'center',
        bottom: 0,
        margin: '30%'
    }
});

export default GamePage;