

import AsyncStorage from '@react-native-async-storage/async-storage';

const getValue = async (key: string) => {
    try {
       const value = await AsyncStorage.getItem(`@${key}`);
       return value;
    } catch (e) {
       console.error(e);
    }
 }


 const storeValue = async (key: string, value: any) => {
   try {
     await AsyncStorage.setItem(`@${key}`, value);
   } catch (e) {
     console.error(e);
   }
 }
 
 
 export const readPlayerFromStorage = async (): Promise<any | undefined> => {
    try {
       const storedPlayer = await getValue("player") as string;
       const playerObject = JSON.parse(storedPlayer);
       return playerObject;
    } catch (e) {
       console.error(e);
    }
    return undefined;
 }

 export const storePlayerInStorage = async (player: any) => {
   await storeValue("player", JSON.stringify(player));
 };