import React from 'react';
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native';
import { styles as GlobalStyles } from "../../styles/styles";

interface IButtonProps {
    primary?: boolean;
    secondary?: boolean;
    disabled?: boolean;
    color?: string;
    textColor?: string;
    buttonStyleOverrides?: any;
    textStyleOverrides?: any;
    onPress?: any;
    text: string;
}

export const Button = ({ primary, color, textColor, disabled, text, secondary, buttonStyleOverrides, textStyleOverrides, onPress }: IButtonProps) => {
    let buttonStyles = [];
    let textStyles = [];
    if (primary) {
        buttonStyles.push(GlobalStyles.primaryButton);
        textStyles.push(GlobalStyles.primaryButtonText);
    } else if (secondary) {
        buttonStyles.push(GlobalStyles.secondaryButton);
        textStyles.push(GlobalStyles.secondaryButtonText);
    }
    buttonStyles.push(buttonStyleOverrides);
    textStyles.push(textStyleOverrides);
    return (
        <View style={[disabled && styles.disabledButton]}>
            <TouchableOpacity
                disabled={disabled}
                style={[buttonStyles, color && { backgroundColor: color }]}
                onPress={() => onPress()}
            >
                <Text style={[textStyles, textColor && { color: textColor }]}>{text}</Text>
            </TouchableOpacity>
        </View>
    );
}

const styles = StyleSheet.create({
    disabledButton: {
        opacity: 0.3
    }
});
export default Button;