import React from 'react';
import { HomePage } from './components/home-page/home-page';
import { LobbyPage } from './components/lobby-page/lobby-page';
import { NewPlayerPage } from './components/new-player-page/new-player-page';
import { JoinCodePage } from './components/join-code-page/join-code-page';
import { RulesPage } from './components/rules-page/rules-page';
import { GamePage } from "./components/game-page/game-page";
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import LobbyServiceContext from './services/lobby-service';
import TelemetryServiceContext, { initTelemetryService } from "./services/telemetry-service";
import { MenuProvider } from 'react-native-popup-menu';

import io from "socket.io-client";
import { LOBBY_SERVICE_URL } from "./constants";
import { Platform } from 'react-native';

const Stack = createStackNavigator();
const lobbyService = io(LOBBY_SERVICE_URL);
const telemetryService = initTelemetryService();

export default function App() {
  return (
    <MenuProvider>
      <TelemetryServiceContext.Provider value={{ telemetryService }}>
        <LobbyServiceContext.Provider value={{ lobbyService }}>
          <NavigationContainer theme={MyTheme}>
            <Stack.Navigator>
              <Stack.Screen name="Home" component={HomePage} options={{ title: "" }} />
              <Stack.Screen name="Lobby" component={LobbyPage} options={{ title: "" }} />
              <Stack.Screen name="Game" component={GamePage} options={Platform.OS !== 'web' ? { title: "", headerLeft: () => undefined } : { title: "" }} />
              <Stack.Screen name="NewPlayer" component={NewPlayerPage} options={{ title: "" }} />
              <Stack.Screen name="JoinCode" component={JoinCodePage} options={{ title: "" }} />
              <Stack.Screen name="RulesPage" component={RulesPage} options={{ title: "" }} />
            </Stack.Navigator>
          </NavigationContainer>
        </LobbyServiceContext.Provider>
      </TelemetryServiceContext.Provider>
    </MenuProvider>
  );
}

const MyTheme = {
  dark: false,
  colors: {
    primary: 'black',
    background: 'black',
    card: '#9b854d',
    text: 'white',
    border: '#9b854d',
    notification: 'rgb(255, 69, 58)',
  },
};
