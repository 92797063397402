import { StyleSheet } from 'react-native';

export const BACKGROUND_COLOR = '#9b854d';
export const DISCONNECTED_COLOR = '#BFBFB8';

export const styles = StyleSheet.create({
    globalBackgroundColor: {
        backgroundColor: BACKGROUND_COLOR
    },
    header: {
        fontSize: 36,
        marginBottom: 48
    },
    textInput: {
        height: 40,
        borderColor: "#000000",
        borderBottomWidth: 1,
        marginBottom: 36
    },
    primaryButton: {
        borderRadius: 25,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 50,
        paddingRight: 50,
        backgroundColor: '#ffffff',
        shadowColor: 'black',
        shadowOpacity: 0.8,
        shadowRadius: 9,
        width: 220,
        height: 50,
        shadowOffset: { width: 1, height: 9 }
     },
     secondaryButton: {
        borderRadius: 25,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 50,
        paddingRight: 50,
        backgroundColor: '#000000',
        shadowColor: 'black',
        shadowOpacity: 0.8,
        shadowRadius: 9,
        width: 220,
        height: 50,
        shadowOffset: { width: 1, height: 9 }
     },
     primaryButtonText: {
        alignContent: 'center',
        textAlign: 'center',
        color: 'black',
        marginTop: 7,
        fontSize: 20
     },
     secondaryButtonText: {
        alignContent: 'center',
        textAlign: 'center',
        color: 'white',
        marginTop: 7,
        fontSize: 20
     },
});
