export const genrateGUID = () => {
    var S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
};


export const isSameSet = (cards: number[][]): boolean => {
    if (!cards || cards.length === 0) {
        return false;
    }
    if (cards.length === 1) {
        return true;
    }

    cards.sort((a: number[], b: number[]) => a[0] - b[0]);
    let prevCard = cards[0][0];
    for (let i = 1; i < cards.length; i++) {
        const currentCard = cards[i][0];
        if (currentCard === 13) {
            continue;
        }
        if (prevCard !== currentCard) {
            return false;
        }
    }
    return true;
};

export const areSetsEqual = (setA: number[][], setB: number[]) => {
    for (let i = 0; i < setA.length; i++) {
        if (setA[i][0] !== setB[i]) {
            return false;
        }
    }
    return true;
};


export const removeIntersectKeepDuplicates = (array: number[], toRemove: number[]) => {
    if (array && toRemove) {
        toRemove.forEach(item => {
            const foundItemIndex = array.findIndex(a => a === item);
            array.splice(foundItemIndex, 1);
        });
    }
    return array;
};

export const findIndiciesOfTaxCards = (taxCards: number[], hand: number[]): number[] => {
    const indicies: number[] = [];
    if (taxCards && taxCards.length > 0) {
        taxCards.forEach(card => {
            const index = hand.indexOf(card);
            if (indicies.includes(index)) {
                const newIndex = hand.lastIndexOf(card);
                if (!indicies.includes(newIndex)) {
                    indicies.push(newIndex);
                }
            } else {
                indicies.push(index);
            }
        })
    }
    return indicies;
};

export const findFindCardInSelectedCards = (indexInHand: number, selectedCards: number[][]) => {
    if (selectedCards) {
        for (let i = 0; i < selectedCards.length; i++) {
            if (indexInHand === selectedCards[i][1]) {
                return i;
            }
        }
    }
    return -1;
};

export const isStrictlyLowerSet = (selectedCards: number[][], stack: number[]): boolean => {
    selectedCards.sort((a: number[], b: number[]) => a[0] - b[0]);
    stack.sort((a: number, b: number) => a - b);
    if (selectedCards.length === 2) {
        if (selectedCards[0][0] === 13 && selectedCards[1][0] === 13) {
            return false;
        }
    }
    for (let i = 0; i < selectedCards.length; i++) {
        const selectedCard = selectedCards[i][0];
        const stackCard = stack[i];
        if (selectedCard === 13) {
            continue;
        }
        if (selectedCard >= stackCard) {
            console.log(`not valid set selected cards: ${selectedCards.map(card => card[0])}, stack: ${stack}`);
            return false;
        }
    }
    return true;
};

export const canUserPlay = (currentPlayerId: string, activePlayerId: string, stack: number[], selectedCards: number[][]) => {
    if (currentPlayerId !== activePlayerId) {
        return false;
    }
    if (!stack && !selectedCards) {
        return false;
    }
    if (!stack || stack.length === 0) {
        return isSameSet(selectedCards);
    }

    if (!selectedCards) {
        return false;
    }

    if (selectedCards.length !== stack.length) {
        return false;
    }

    if (!isSameSet(selectedCards)) {
        return false;
    }

    return isStrictlyLowerSet(selectedCards, stack);
};

export const getStatusText = (playerName: string, cards: number[]): string => {
    if (!cards) return "";
    if (cards && cards.length === 0) {
        return "No cards played";
    }
    const cardsDict: any = {};
    cards.forEach(card => {
        if (cardsDict[`${card}`]) {
            cardsDict[`${card}`] = cardsDict[`${card}`] + 1;
        } else {
            cardsDict[`${card}`] = 1;
        }
    });
    let text = `${playerName} played `;
    const englishNumber = ["zero", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten", "eleven", "twelve", "thirteen"];
    const keys = Object.keys(cardsDict);
    keys.forEach((key, index) => {
        text += `${(index === keys.length - 1 && index !== 0) ? "and " : ""}${englishNumber[cardsDict[`${key}`]]} ${key === "13" ? "wild" : key}${cardsDict[`${key}`] !== 1 ? "s" : ""}, `
    });
    return text.slice(0, -2);
};

export const removeCardsFromHand = (cardsToRemove: number[], hand?: number[]) => {
    if (!cardsToRemove || !hand) {
        return hand;
    }
    cardsToRemove.forEach(cardToRemove => {
        hand.splice(hand.indexOf(cardToRemove), 1);
    });
    return hand;
};

export const canFindNumbersInSet = (numbersToFind: number[], set: number[]): boolean => {
    if (numbersToFind && set && numbersToFind.length > 0 && set.length > 0) {
        let newSet = set.slice();
        for (let i = 0; i < numbersToFind.length; i++) {
            const index = newSet.findIndex(n => n === numbersToFind[i]);
            if (index === -1) {
                return false;
            }
            newSet.splice(index, 1);
        }
        return true;
    }
    return false;
};

export const insertArrayAtIndex = (toInsert: any[], arr: any[], index: number): any[] => {
    if (toInsert && arr && toInsert.length > 0 && arr.length > 0) {
        return [
            ...arr.slice(0, index),
            ...toInsert,
            ...arr.slice(index)
        ];
    }
    return arr;

};

export const removeDepartedPlayers = (game: any) => {
    if (game && game.players && game.players.length > 0) {
        const updatedPlayerList: any[] = [];
        let nextFinishOrder = 0;
        game.players.forEach((player: any) => {
            if (!player.hasLeft) {
                player.finishOrder = nextFinishOrder;
                updatedPlayerList.push(player);
                nextFinishOrder++;
            }
        });
        game.players = updatedPlayerList;
    }
};