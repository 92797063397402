import React from 'react';
import { Platform, StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { Player, Game, PlayerType } from "../../data/interfaces";
import LobbyServiceContext from '../../services/lobby-service';
import axios from 'axios';
import { LOBBY_SERVICE_URL, BOT_SERVICE_URL, MAX_PLAYER_LIMIT } from "../../constants";
import { readPlayerFromStorage } from "../../services/player-service";
import _ from 'lodash';
import { Button } from "../button/button";
import { useMyDeviceOrientation } from "../../services/device-orientation";
import TelemetryServiceContext from "../../services/telemetry-service";
import ExpoConstants from 'expo-constants';
import DraggableFlatList, {
    RenderItemParams,
} from 'react-native-draggable-flatlist';
import {
    Menu,
    MenuOptions,
    MenuOption,
    MenuTrigger,
} from 'react-native-popup-menu';
import { showAlert } from "../alert/alert";
import { CheckBox, Image } from 'react-native-elements';
import { styles as GlobalStyles, BACKGROUND_COLOR, DISCONNECTED_COLOR } from "../../styles/styles";
if (Platform.OS == 'web') {
    document.body.style.backgroundColor = BACKGROUND_COLOR;
}

interface ILobbyPageProps {
    navigation: any;
    route: any;
}
interface ILobbyProps {
    game: Game;
    player: Player;
    isLobbyOwner: boolean;
    navigation: any;
    lobbyService: any;
}


export const LobbyPage = ({ navigation, route }: ILobbyPageProps) => {
    const joinCode = route && route.params && route.params.joinCode;
    const [player, setPlayer] = React.useState(route && route.params && route.params.player);
    const [isTaxSession, setIsTaxSession] = React.useState(false);
    const isLobbyOwner = route && route.params && route.params.isLobbyOwner;
    const { telemetryService } = React.useContext(TelemetryServiceContext);

    const [game, setGame] = React.useState({} as Game);
    const [errorString, setErrorString] = React.useState("");
    const { lobbyService } = React.useContext(LobbyServiceContext);

    const onJoinGameEvent = (sender: any, game: Game) => {
        console.log(`New player joined or left game: ${sender && sender.displayName}`);
        setGame(game);
    }

    const onGameUpdateEvent = (sender: any, game: Game) => {
        if (game.isStarted) {
            navigation.navigate("Game", { joinCode, player });
        }
        setGame(game);
    }

    React.useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            readPlayerFromStorage().then(p => {
                console.log(`Read ${p && p.displayName} from storage`);
                setPlayer(p);
                fetchGame(p);
            }).catch(error => {
                console.error(`Error reading player from storage: ${error}`);
            });
        });

        return unsubscribe;
    }, [navigation]);

    const subscribeToJoinGameEvents = React.useCallback(() => {
        lobbyService.on('joinGame', onJoinGameEvent);
    }, [joinCode]);

    const subscribeToGameUpdateEvents = React.useCallback(() => {
        lobbyService.on('gameUpdate', onGameUpdateEvent);
    }, [joinCode]);

    const unsubscribeFromGameUpdateEvents = () => {
        lobbyService.off('gameUpdate', onGameUpdateEvent);
    }

    const unsubscribeFromJoinGameEvents = () => {
        lobbyService.off('joinGame', onJoinGameEvent);
    }

    const onUpdatePlayerOrder = (players: any) => {
        console.log("Updating player order");
        const gameCopy = Object.assign({}, game);
        gameCopy.players = players;
        setGame(gameCopy);
        lobbyService.emit("gameUpdate", player, gameCopy);
    }

    const onRemovePlayer = (p: Player) => {
        const gameCopy = Object.assign({}, game);
        if (!gameCopy.isStarted) {
            const playerToRemoveIndex = gameCopy.players.findIndex(x => x.id == p.id);
            if (playerToRemoveIndex !== -1) {
                setGame(gameCopy);
                gameCopy.players.splice(playerToRemoveIndex, 1);
                lobbyService.emit("gameUpdate", player, gameCopy);
            } else {
                console.error(`Couldn't find player ${p.displayName} in game`);
            }
        } else {
            const currentPlayer = findCurrentPlayerInGame(p.id, gameCopy);
            if (currentPlayer) {
                currentPlayer.hasLeft = true;
                if (gameCopy.activePlayer === currentPlayer.id) {
                    pass(currentPlayer, gameCopy);
                };
                setGame(gameCopy);
                lobbyService.emit("gameUpdate", p, gameCopy);
            } else {
                console.error(`Couldn't find player ${p.displayName} in game`);
            }
        }
    }

    const onTaxSessionSelected = (checked: boolean) => {
        console.log(`Tax session checked ${checked ? 'enabled' : 'disabled'}`);
        setIsTaxSession(checked);
    };

    const pass = (targetedPlayer: Player, game: Game) => {
        console.log(`Player ${targetedPlayer.displayName} passed their turn.`);
        const currPlayerIndex = game.players.findIndex(p => p.id === targetedPlayer.id);
        game.activePlayer = getNextPlayerId(currPlayerIndex, game.players);
        if (!game.passList || game.passList.length === 0) {
            game.passList = [targetedPlayer.id];
        } else {
            game.passList.push(targetedPlayer.id);
        }
    }

    const fetchGame = (p: any) => {
        axios.get(`${LOBBY_SERVICE_URL}/api/game/${joinCode}`).then(response => {
            const game = response.data;
            console.log(`Game with joinCode ${game.joinCode} received`);
            setGame(game);
        }).catch(error => {
            let errorString = "";
            let status;
            if (error.response && error.response.status === 404) {
                status = error.response.status;
                errorString = `Sorry, it looks like the game for join code ${joinCode.toUpperCase()} was not found :(. Swipe back and try another join code.`;
                setErrorString(errorString);
            } else {
                errorString = `Sorry, there was an issue joining the game for join code "${joinCode.toUpperCase()}". Double check your join code and try again.`;
                setErrorString(errorString);
            }
            telemetryService && telemetryService.trackEvent({ name: 'error_page_view', properties: { statusCode: status, error: errorString, playerId: p && p.id, displayName: p && p.displayName, clientVersion: ExpoConstants && ExpoConstants.manifest && ExpoConstants.manifest.version, deviceId: ExpoConstants && ExpoConstants.deviceId } });
            console.error(error.message);
        });
    };

    const onAddBot = () => {
        telemetryService && telemetryService.trackEvent({ name: 'add_bot_click', properties: { joinCode: joinCode, playerId: player && player.id, displayName: player && player.displayName, clientVersion: ExpoConstants && ExpoConstants.manifest && ExpoConstants.manifest.version, deviceId: ExpoConstants && ExpoConstants.deviceId } });
        axios.post(`${BOT_SERVICE_URL}/api/bots/${joinCode}`).then(response => {
            const bot = response.data;
            console.log(`Bot ${bot.displayName} created`);
            telemetryService && telemetryService.trackEvent({ name: 'add_bot_success', properties: { joinCode: joinCode, botId: bot.id, botName: bot.displayName, playerId: player && player.id, displayName: player && player.displayName, clientVersion: ExpoConstants && ExpoConstants.manifest && ExpoConstants.manifest.version, deviceId: ExpoConstants && ExpoConstants.deviceId } });
        }).catch(error => {
            telemetryService && telemetryService.trackEvent({ name: 'add_bot_failure', properties: { joinCode: joinCode, error: JSON.stringify(error), playerId: player && player.id, displayName: player && player.displayName, clientVersion: ExpoConstants && ExpoConstants.manifest && ExpoConstants.manifest.version, deviceId: ExpoConstants && ExpoConstants.deviceId } });
            console.error(error);
        });
    }

    const canAddBot = () => {
        if (game && !game.isStarted && game.players && game.players.length < MAX_PLAYER_LIMIT) {
            return true;
        }
        return false;
    }

    React.useEffect(() => {
        subscribeToJoinGameEvents();
        subscribeToGameUpdateEvents();
        return () => {
            unsubscribeFromGameUpdateEvents();
            unsubscribeFromJoinGameEvents();
        }
    }, [joinCode]);

    React.useEffect(() => {
        readPlayerFromStorage().then(p => {
            telemetryService && telemetryService.trackEvent({ name: 'lobby_page_view', properties: { playerId: p && p.id, displayName: p && p.displayName, joinCode, clientVersion: ExpoConstants && ExpoConstants.manifest && ExpoConstants.manifest.version, deviceId: ExpoConstants && ExpoConstants.deviceId } });
            setPlayer(p);
            fetchGame(p);
        });
    }, [joinCode]);

    return (
        errorString && errorString !== "" ?
            <ErrorPage errorString={errorString} /> :
            <Lobby onAddBot={onAddBot} canAddBot={canAddBot()} onUpdatePlayerOrder={onUpdatePlayerOrder} isTaxSession={isTaxSession} onTaxSessionSelected={onTaxSessionSelected} onRemovePlayer={onRemovePlayer} game={game} player={player} isLobbyOwner={isLobbyOwner} navigation={navigation} lobbyService={lobbyService} setGame={setGame} />
    );
}

const ErrorPage = ({ errorString }: any) => {
    return (
        <View style={styles.errorContainer}>
            <Text selectable={true} style={GlobalStyles.header}>{errorString}</Text>
        </View>
    )
}

type Item = {
    disconnected: boolean;
    displayName: string;
    hasLeft: boolean;
    id: string;
    type: PlayerType;
};

const Lobby = ({ onAddBot, canAddBot, onUpdatePlayerOrder, isTaxSession, onTaxSessionSelected, onRemovePlayer, game, player, isLobbyOwner, navigation, lobbyService, setGame }: any) => {
    const landscape = useMyDeviceOrientation() === "landscape";
    const { telemetryService } = React.useContext(TelemetryServiceContext);

    const renderPlayerItem = (item: Item, isActive: boolean, index: number) => {
        return (
            <Text style={[styles.playerText, item.disconnected && styles.playerDisconnectedText, isActive && { color: 'white' }, item.hasLeft && styles.playerLeftText]}>{index + 1}. {item.displayName} {item.type === PlayerType.BOT ? "(Bot)" : ""} {item.disconnected ? "(offline)" : ""}</Text>
        );
    }

    const isBotButton = (item: Item) => {
        return item.id === 'addBotButton';
    }
    const renderItem = React.useCallback(
        ({ item, index, drag, isActive }: RenderItemParams<Item>) => {
            if (index !== undefined && game) {
                return (
                    <Menu onSelect={value => {
                        if (value === 2) {
                            if (game.players.length === 1) {
                                showAlert("Failed to remove user", "You cannot remove the last player from the game.",
                                    [
                                        { text: "OK", onPress: () => { } }
                                    ],
                                    { cancelable: false });
                                return;
                            }
                            showAlert(
                                "Remove user",
                                `Are you sure you want to remove ${item.displayName} from the game?`,
                                [
                                    {
                                        text: "No",
                                        onPress: () => console.log("Cancel Pressed"),
                                        style: "cancel"
                                    },
                                    { text: "Remove", onPress: () => onRemovePlayer(item) }
                                ],
                                { cancelable: false }
                            );

                        }
                    }}>
                        <MenuTrigger onAlternativeAction={!game.isStarted && player.id === game.owner ? drag : () => { }}>
                            {renderPlayerItem(item, isActive, index)}
                        </MenuTrigger>
                        <MenuOptions customStyles={{ optionsContainer: { backgroundColor: 'red', borderRadius: 6, width: 100, height: 30, marginTop: 53, marginLeft: 40 } }}>
                            <MenuOption style={{ flexDirection: 'row', alignItems: 'center', width: 'auto' }} value={2}>
                                <Image
                                    style={styles.removeIcon}
                                    source={require('../../assets/icons/remove-icon.png')}></Image>
                                <Text style={{ color: 'white', paddingTop: 0, paddingLeft: 4 }}>Remove</Text>
                            </MenuOption>
                        </MenuOptions>
                    </Menu>

                );
            }
            return null;
        },
        [game]
    );

    return (
        <View style={styles.background}>
            <View style={styles.joinCodeContainer}>
                <Text selectable={true} style={styles.joinCodeText}>{game && game.joinCode && game.joinCode.toUpperCase()}</Text>
            </View>
            {game && game.players && <View style={[landscape ? styles.lobbyContainerHorizontal : styles.lobbyContainerVertical]}>
                <DraggableFlatList
                    style={{ maxHeight: landscape ? '50%' : '80%' }}
                    data={game.spectators ? game.players.concat(game.spectators) : game.players}
                    renderItem={renderItem}
                    keyExtractor={(item, index) => `draggable-item-${index}`}
                    onDragEnd={({ data }) => {
                        onUpdatePlayerOrder(data);
                    }}
                />
            </View>}
            {(isLobbyOwner || player.id === game.owner || game.isStarted) && renderGameButtons(landscape, game, player, navigation, lobbyService, setGame, onTaxSessionSelected, isTaxSession, telemetryService, onAddBot, canAddBot)}
        </View>
    )
};

const renderBotButton = (onAddBot: any) => {
    return (
        <View style={[{ marginBottom: 15 }]}>
            <Button buttonStyleOverrides={{}} primary onPress={() => onAddBot()} text={"Add Bot"}></Button>
        </View>
    )
}
const renderGameButtons = (landscape: boolean, game: Game, player: Player, navigation: any, lobbyService: any, setGame: any, onTaxSessionSelected: any, isTaxSessionSelected: boolean, telemetryService: any, onAddBot: any, canAddBot: boolean) => {
    return (
        <View style={[landscape ? styles.startButtonContainerHorizontal : styles.startButtonContainerVertical]}>
            {canAddBot && renderBotButton(onAddBot)}
            <Button secondary={true} onPress={() => onStartButtonPress(game, player, isTaxSessionSelected, navigation, lobbyService, setGame, telemetryService)} text={game.isStarted ? "Join Game" : "Start Game"}></Button>
            <CheckBox
                center
                disabled={!canRenderTaxSessionCheckbox(game, player)}
                title='Start with Taxes'
                checkedIcon={<Image style={styles.removeIcon} source={require('../../assets/icons/checked-icon.png')} />}
                uncheckedIcon={<Image style={styles.removeIcon} source={require('../../assets/icons/unchecked-icon.png')} />}
                checked={isTaxSessionSelected}
                onPress={() => onTaxSessionSelected(!isTaxSessionSelected)}
                containerStyle={{ backgroundColor: "", borderColor: "", borderWidth: 0, padding: 0, margin: 0, paddingTop: 20 }}
                textStyle={{ color: 'black', fontSize: 18, fontWeight: '600' }}
                wrapperStyle={{ opacity: !canRenderTaxSessionCheckbox(game, player) ? 0.2 : 1 }}
            />
        </View>
    )
}

const canRenderTaxSessionCheckbox = (game: Game, player: Player): boolean => {
    return game && player && !game.isStarted && game.owner === player.id && game.players && game.players.length >= 4;
}

const onStartButtonPress = (game: Game, player: Player, isTaxSessionSelected: boolean, navigation: any, lobbyService: any, setGame: any, telemetryService: any) => {
    if (game.isStarted) {
        console.log('Game already started. Re-joining game');
        lobbyService.emit("joinGame", player, game.joinCode);
        telemetryService && telemetryService.trackEvent({ name: 'rejoin_game', properties: { gameId: game.id, joinCode: game.joinCode, gameSize: game.players.length, playerId: player && player.id, displayName: player && player.displayName, clientVersion: ExpoConstants && ExpoConstants.manifest && ExpoConstants.manifest.version, deviceId: ExpoConstants && ExpoConstants.deviceId } });
        navigation.navigate("Game", { joinCode: game.joinCode, player });
    } else {
        console.log("Starting game");
        const gameCopy = Object.assign({}, game);
        gameCopy.isStarted = true;
        gameCopy.activePlayer = gameCopy.players[0].id;
        gameCopy.players = createHands(gameCopy.players);
        if (isTaxSessionSelected && gameCopy.players.length >= 4) {
            gameCopy.isTaxSession = true;
            gameCopy.statusText = 'Taxes are in progress';
            setPlayersWhoPayTaxes(gameCopy, false);
            gameCopy.activePlayer = "";
        }
        setGame(gameCopy);
        lobbyService.emit("gameUpdate", player, gameCopy);
        telemetryService && telemetryService.trackEvent({ name: 'start_game', properties: { gameId: game.id, isTaxSession: gameCopy.isTaxSession, joinCode: game.joinCode, gameOwner: player && player.id, gameSize: game.players.length, playerId: player && player.id, displayName: player && player.displayName, clientVersion: ExpoConstants && ExpoConstants.manifest && ExpoConstants.manifest.version, deviceId: ExpoConstants && ExpoConstants.deviceId } });
        navigation.navigate("Game", { joinCode: gameCopy.joinCode, player });
    }
}

const setPlayersWhoPayTaxes = (game: Game, hasPaidTaxes: boolean) => {
    if (game && game.players && game.players.length > 2) {
        game.players[0].hasPaidTaxes = hasPaidTaxes;
        game.players[0].finishOrder = 0;
        game.players[1].hasPaidTaxes = hasPaidTaxes;
        game.players[1].finishOrder = 1;
        game.players[game.players.length - 1].hasPaidTaxes = hasPaidTaxes;
        game.players[game.players.length - 1].finishOrder = game.players.length - 1;
        game.players[game.players.length - 2].hasPaidTaxes = hasPaidTaxes;
        game.players[game.players.length - 2].finishOrder = game.players.length - 2;
    }
};

const findCurrentPlayerInGame = (playerId: string, game: Game) => {
    return game && game.players && game.players.find(p => p.id === playerId) || game && game.spectators && game.spectators.find(p => p.id === playerId);
};

const getNextPlayerId = (startingIndex: number, players: any): string => {
    let currentIndex = (startingIndex + 1) % players.length;
    while (startingIndex !== currentIndex) {
        if (players[currentIndex].hand && players[currentIndex].hand.length > 0 && !players[currentIndex].hasLeft) {
            return players[currentIndex].id;
        }
        currentIndex = (currentIndex + 1) % players.length;
    }
    return players[startingIndex].id;
};

export const createDeck = () => {
    const deck: number[] = [];
    for (let i = 1; i < 14; i++) {
        addSetOfNumberToDeck(i, deck);
    }
    return _.shuffle(deck);
}

export const addSetOfNumberToDeck = (num: number, deck: number[]) => {
    if (num === 13) {
        deck.push(13);
        deck.push(13);
        return;
    }
    for (let i = num; i > 0; i--) {
        deck.push(num);
    }
}

export const getRandomInt = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
}

export const addCardToPlayersHand = (player: any, card: number) => {
    if (!player.hand) {
        player.hand = [card];
    } else {
        player.hand.push(card);
    }
}

export const createHands = (players: any) => {
    if (players && players.length > 0) {
        const deck = createDeck();
        let playerIndex = 0;
        clearHands(players);
        while (deck.length > 0) {
            const randomIndex = getRandomInt(0, deck.length);
            const currentPlayer = players[playerIndex % players.length];
            addCardToPlayersHand(currentPlayer, deck[randomIndex]);
            deck.splice(randomIndex, 1);
            playerIndex++;
        }
    }
    return players;
}

export const clearHands = (players: any) => {
    if (players && players.length > 0) {
        players.forEach((player: any) => {
            player.hand = [];
        });
    }
    return players;
}

const styles = StyleSheet.create({
    background: {
        flex: 1,
        backgroundColor: BACKGROUND_COLOR,
        alignItems: 'center',
        justifyContent: 'center',
    },
    errorContainer: {
        flex: 1,
        backgroundColor: BACKGROUND_COLOR,
        padding: 24,
    },
    joinCodeContainer: {
        marginTop: 80
    },
    joinCodeText: {
        fontSize: 40
    },
    lobbyContainerVertical: {
        alignSelf: 'flex-start',
        paddingLeft: 20,
        height: '100%'
    },
    removeIcon: {
        width: 20,
        height: 20
    },
    lobbyContainerHorizontal: {
        alignSelf: 'flex-start',
        paddingLeft: 40,
        height: '100%',
        width: '100%'
    },
    playerText: {
        fontSize: 30,
        paddingTop: 15
    },
    addBotText: {
        fontSize: 20,
        paddingTop: 30,
        color: 'yellow',
        marginLeft: 5
    },
    playerDisconnectedText: {
        color: DISCONNECTED_COLOR
    },
    playerLeftText: {
        color: 'red'
    },
    startButtonContainerVertical: {
        position: 'absolute',
        alignItems: 'center',
        bottom: 70
    },
    startButtonContainerHorizontal: {
        position: 'absolute',
        alignItems: 'center',
        bottom: 30
    }
});
export default LobbyPage;