export enum PlayerType {
    BOT = 'BOT',
    HUMAN = 'HUMAN'
}
export interface Player {
    id: string;
    displayName: string;
    type: PlayerType,
    disconnected?: boolean;
    hand?: number[];
    finishOrder?: number;
    hasLeft?: boolean;
    hasPaidTaxes?: boolean;
    receivedTaxes?: number[];
}

export interface Game {
    id: string;
    isStarted: boolean;
    isTaxSession: boolean;
    areTaxesInProgres: boolean;
    areTaxesInProgress: boolean;
    statusText: string;
    finishOrderIndex: number;
    passList: string[];
    owner: string;
    stack: number[];
    activePlayer: string;
    lastPlayed: string;
    joinCode: string;
    gameType: string;
    history: number[][];
    players: Player[];
    spectators: Player[];
}