import React from 'react';
import { View, TouchableOpacity, KeyboardAvoidingView, TextInput, StyleSheet, Text, Platform, TouchableWithoutFeedback, Button, Keyboard } from 'react-native';
import axios from 'axios';
import { LOBBY_SERVICE_URL, GAME_TYPE } from "../../constants";
import { Player, PlayerType } from "../../data/interfaces";
import { InputContainer } from "../input-container/input-container";
import TelemetryServiceContext from "../../services/telemetry-service";
import ExpoConstants from 'expo-constants';
import { styles as GlobalStyles, BACKGROUND_COLOR } from "../../styles/styles";
import LobbyServiceContext from '../../services/lobby-service';
import { storePlayerInStorage, readPlayerFromStorage } from "../../services/player-service";
import { genrateGUID } from "../../utility/utility";
interface INewPlayerPageProps {
    navigation: any;
    route: any;
}

export const NewPlayerPage = ({ navigation, route }: INewPlayerPageProps) => {
    const [displayName, setDisplayName] = React.useState("");
    const isLobbyOwner = route && route.params && route.params.isLobbyOwner;
    const joinCode = route && route.params && route.params.joinCode;
    const isChangeName = route && route.params && route.params.isChangeName;
    const { lobbyService } = React.useContext(LobbyServiceContext);
    const [player, setPlayer] = React.useState({} as any);
    const { telemetryService } = React.useContext(TelemetryServiceContext);

    React.useEffect(() => {
        readPlayerFromStorage().then(p => {
            console.log(`Read ${p && p.displayName} from storage`);
            setPlayer(p);
            telemetryService && telemetryService.trackEvent({ name: 'new_player_page_view', properties: { playerId: p && p.id, displayName: p && p.displayName, clientVersion: ExpoConstants && ExpoConstants.manifest && ExpoConstants.manifest.version, deviceId: ExpoConstants && ExpoConstants.deviceId } });
        }).catch(error => {
            console.error(`Error reading player from storage: ${error}`);
            telemetryService && telemetryService.trackEvent({ name: 'new_player_page_view', properties: { playerId: undefined, displayName: undefined, clientVersion: ExpoConstants && ExpoConstants.manifest && ExpoConstants.manifest.version, deviceId: ExpoConstants && ExpoConstants.deviceId } });
        });
    }, []);

    return (
        <KeyboardAvoidingView
            behavior={Platform.OS == "ios" ? "padding" : "height"}
            style={styles.container}
        >
            <InputContainer>
                <View style={styles.inner}>
                    <Text style={GlobalStyles.header}>Enter your name</Text>
                    <TextInput autoCapitalize="none" keyboardAppearance='dark' autoCorrect={false} spellCheck={false} selectionColor='black' placeholderTextColor='black' onChangeText={text => setDisplayName(text)} placeholder="Name" style={GlobalStyles.textInput} />
                    <View style={styles.btnContainer}>
                        {displayName ? <TouchableOpacity
                            style={[GlobalStyles.secondaryButton, styles.saveButton]}
                            onPress={() => onSaveButtonPressed(displayName, isLobbyOwner, joinCode, navigation, lobbyService, player, isChangeName)}
                        >
                            <Text style={GlobalStyles.secondaryButtonText}>Save</Text>
                        </TouchableOpacity> : null}
                    </View>
                </View>
            </InputContainer>
        </KeyboardAvoidingView>
    );
};

const onSaveButtonPressed = async (displayName: string, isLobbyOwner: boolean, joinCode: string, navigation: any, lobbyService: any, storedPlayer?: any, isChangeName?: boolean) => {
    const player = {
        id: storedPlayer && storedPlayer.id || genrateGUID(),
        displayName,
        type: PlayerType.HUMAN
    };
    await storePlayerInStorage(player);
    console.log(`Stored ${player.displayName} in storage with id ${player.id}`)
    if (isChangeName) {
        navigation.goBack();
        return;
    }
    if (isLobbyOwner) {
        createGameAndNavigateToLobby(navigation, lobbyService, player, isLobbyOwner);
    } else {
        navigateToJoinCodePage(navigation, joinCode, isLobbyOwner);
    }
}

const createGameAndNavigateToLobby = (navigation: any, lobbyService: any, player: Player, isLobbyOwner: boolean) => {
    console.log("Creating a new game");
    axios.post(`${LOBBY_SERVICE_URL}/api/game`, {
        gameType: GAME_TYPE.Dalmuti
    }).then(response => {
        const game = response.data;
        console.log(`Game with joinCode ${game.joinCode} created`);
        lobbyService.emit("joinGame", player, game.joinCode);
        navigation.navigate("Lobby", { joinCode: game.joinCode, isLobbyOwner });
    });
}

const navigateToJoinCodePage = (navigation: any, joinCode: string, isLobbyOwner: boolean) => {
    console.log("Navigating to join code page.");
    navigation.navigate("JoinCode", { joinCode, isLobbyOwner });
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: BACKGROUND_COLOR
    },
    inner: {
        padding: 24,
        flex: 1,
        justifyContent: "space-around",
        backgroundColor: BACKGROUND_COLOR
    },
    btnContainer: {
        flex: 1
    },
    saveButton: {
        width: '100%'
    }
});

export default NewPlayerPage;