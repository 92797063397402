import React from 'react'
import { APP_INSIGHTS_KEY } from "../constants";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

interface ITelemetryService {
    telemetryService: any | undefined;
}
const TelemetryServiceContext = React.createContext({telemetryService: undefined} as ITelemetryService);

export const initTelemetryService = () => {
    var reactPlugin = new ReactPlugin();
    var appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: APP_INSIGHTS_KEY,
            extensions: [reactPlugin],
            extensionConfig: {
            }
        }
    });
    if (appInsights) {
        appInsights.loadAppInsights();
    }
    return appInsights;
};

export default TelemetryServiceContext