import React from 'react';
import { View, KeyboardAvoidingView, TextInput, StyleSheet, Text, Platform, TouchableWithoutFeedback, TouchableOpacity, Keyboard } from 'react-native';
import { Player } from "../../data/interfaces";
import LobbyServiceContext from '../../services/lobby-service';
import { styles as GlobalStyles, BACKGROUND_COLOR } from "../../styles/styles";
import { readPlayerFromStorage } from "../../services/player-service";
import { InputContainer } from "../input-container/input-container";
import TelemetryServiceContext from "../../services/telemetry-service";
import ExpoConstants from 'expo-constants';
interface IJoinCodePageProps {
    navigation: any;
    route: any;
}

export const JoinCodePage = ({ navigation, route }: IJoinCodePageProps) => {
    const [joinCode, setJoinCode] = React.useState("");
    const isLobbyOwner = route && route.params && route.params.isLobbyOwner;
    const [player, setPlayer] = React.useState(route && route.params && route.params.player);
    const { lobbyService } = React.useContext(LobbyServiceContext);
    const { telemetryService } = React.useContext(TelemetryServiceContext);

    React.useEffect(() => {
        console.log(`Reading player from storage`);
        readPlayerFromStorage().then(p => {
            console.log(`Found player: ${p.displayName}`);
            setPlayer(p);
            telemetryService && telemetryService.trackEvent({ name: 'join_code_page_view', properties: { playerId: p && p.id, displayName: p && p.displayName, clientVersion: ExpoConstants && ExpoConstants.manifest && ExpoConstants.manifest.version, deviceId: ExpoConstants && ExpoConstants.deviceId } });
        }).catch(error => {
            console.error(`Error reading player from storage: ${error}`);
            telemetryService && telemetryService.trackEvent({ name: 'join_code_page_view', properties: { playerId: undefined, displayName: undefined, clientVersion: ExpoConstants && ExpoConstants.manifest && ExpoConstants.manifest.version, deviceId: ExpoConstants && ExpoConstants.deviceId } });
        });

    }, []);

    return (
        <KeyboardAvoidingView
            behavior={Platform.OS == "ios" ? "padding" : "height"}
            style={styles.container}
        >
            <InputContainer>
                <View style={styles.inner}>
                    <Text style={GlobalStyles.header}>Enter join code</Text>
                    <TextInput autoCapitalize="none" keyboardAppearance='dark' autoCorrect={false} spellCheck={false} selectionColor='black' placeholderTextColor='black' onChangeText={text => setJoinCode(text)} placeholder="Join code" style={GlobalStyles.textInput} />
                    <View style={styles.btnContainer}>
                        {joinCode ? <TouchableOpacity
                            style={[GlobalStyles.secondaryButton, styles.saveButton]}
                            onPress={() => onSaveButtonPressed(joinCode, isLobbyOwner, navigation, lobbyService, player)}
                        >
                            <Text style={GlobalStyles.secondaryButtonText}>Join</Text>
                        </TouchableOpacity> : null}
                    </View>
                </View>
            </InputContainer>
        </KeyboardAvoidingView>
    );
};

const onSaveButtonPressed = async (joinCode: string, isLobbyOwner: boolean, navigation: any, lobbyService: any, player: Player) => {
    joinCode = joinCode.toLowerCase();
    if (player && player.id) {
        joinGameAndNavigateToLobby(navigation, lobbyService, player, joinCode, isLobbyOwner);
    } else {
        navigateToNewPlayerPage(navigation, joinCode, isLobbyOwner);
    }
}

const joinGameAndNavigateToLobby = (navigation: any, lobbyService: any, player: Player, joinCode: string, isLobbyOwner: boolean) => {
    console.log("Joining an existing game.");
    lobbyService.emit("joinGame", player, joinCode);
    navigation.navigate("Lobby", { joinCode, isLobbyOwner, player });
}

const navigateToNewPlayerPage = (navigation: any, joinCode: string, isLobbyOwner: boolean) => {
    console.log("Navigating to new player page.");
    navigation.navigate("NewPlayer", { joinCode, isLobbyOwner });
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: BACKGROUND_COLOR
    },
    inner: {
        padding: 24,
        flex: 1,
        justifyContent: "space-around"
    },
    btnContainer: {
        flex: 1
    },
    saveButton: {
        width: '100%'
    }
});

export default JoinCodePage;