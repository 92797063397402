import React from 'react';
import { View, StyleSheet, Text, SafeAreaView, ScrollView, Image } from 'react-native';
import { BACKGROUND_COLOR, DISCONNECTED_COLOR } from "../../styles/styles";
import { Avatar } from 'react-native-elements';
import { Button } from "../button/button";
import { readPlayerFromStorage } from "../../services/player-service";
import TelemetryServiceContext from "../../services/telemetry-service";
import ExpoConstants from 'expo-constants';

const card8 = require("../../assets/cards/8.png");
const card5 = require("../../assets/cards/5.png");
const card13 = require("../../assets/cards/13.png");
interface IRulesPage {
    navigation: any;
    route: any;
}

export const RulesPage = ({ navigation, route }: IRulesPage) => {
    const { telemetryService } = React.useContext(TelemetryServiceContext);

    React.useEffect(() => {
        console.log(`Reading player from storage`);
        readPlayerFromStorage().then(p => {
            console.log(`Found player: ${p && p.displayName}`);
            telemetryService && telemetryService.trackEvent({ name: 'rules_page_view', properties: { playerId: p && p.id, displayName: p && p.displayName, clientVersion: ExpoConstants && ExpoConstants.manifest && ExpoConstants.manifest.version, deviceId: ExpoConstants && ExpoConstants.deviceId } });
        }).catch(error => {
            console.error(error);
            telemetryService && telemetryService.trackEvent({ name: 'rules_page_view', properties: { playerId: undefined, displayName: undefined, clientVersion: ExpoConstants && ExpoConstants.manifest && ExpoConstants.manifest.version, deviceId: ExpoConstants && ExpoConstants.deviceId } });
        });
    }, []);
    return (
        <SafeAreaView style={styles.container}>
            <ScrollView style={styles.scrollView}>
                <View style={styles.centerContainer}>
                    <Text style={styles.text}>
                        "Blessed are thee who are impoverished!"
                    </Text>
                    <Text style={[styles.text, styles.pushRight]}>
                        -Beggar
                    </Text>
                </View>
                <View style={styles.leftContainer}>
                    <Text style={[styles.leftText, styles.margin]}>
                        Players:
                    </Text>
                    <Text style={styles.mediumText}>
                        4 - 9
                    </Text>
                    <Text style={[styles.leftText, styles.margin]}>
                        Game Time:
                    </Text>
                    <Text style={styles.mediumText}>
                        ~15 minutes
                    </Text>
                    <Text style={[styles.leftText, styles.margin]}>
                        Cards:
                    </Text>
                    <Text style={styles.mediumText}>
                        There are 80 total cards. There are twelve 12s, eleven 11s, ten 10s, ...etc, and two wild cards (13).
                    </Text>
                    <Text style={[styles.leftText, styles.margin]}>
                        Goal:
                    </Text>
                    <Text style={styles.mediumText}>
                        Increase your social rank every round to become the Emperor. The sooner you get rid of all of your cards, the higher your rank.
                        Whatever you do, do NOT become the Beggar (the last one with cards remaining).
                    </Text>
                    <Text style={[styles.leftText, styles.margin]}>
                        Game play:
                    </Text>
                    <Text style={styles.mediumText}>
                        The game begins with the 80 cards distributed evenly across all the players. In the first game, there are no social ranks.
                        The lobby host begins the game by playing any number of cards with the same rank. The next player will have the option to play cards if they are
                        able to play the same number of cards but with a higher rank.
                    </Text>
                    <Text style={[styles.mediumText, { marginTop: 10 }]}>
                        For example, let's say the first player played three 8s. The next player will have to play
                        3 cards that have the same rank that is higher than 8. Let's say they play three 5s. The game will move on to the next player to try to
                        beat the cards that are now on the stack. NOTE: the wild cards (13) can take the same rank as the cards they are paired with.
                        If they are played alone their rank is 13.
                    </Text>
                    <View style={{ height: 400, marginTop: 20, flexDirection: 'row', flex: 1 }}>
                        <View style={[{ alignContent: 'center', alignItems: 'center', flex: 1, flexDirection: 'column' }]}>
                            <Text style={{ textAlign: 'center', alignSelf: 'center', marginTop: 100, marginBottom: 20 }}>Stack</Text>
                            <View style={{ position: 'relative', alignItems: 'center' }}>
                                <View style={styles.firstStack}>
                                    <Image
                                        style={[styles.cardImage]}
                                        source={card8}
                                    />
                                </View>
                                <View style={styles.firstStack}>
                                    <Image
                                        style={[styles.cardImage, { transform: [{ rotate: `+15deg` }] }]}
                                        source={card8}
                                    />
                                </View>
                                <View style={styles.firstStack}>
                                    <Image
                                        style={[styles.cardImage, { transform: [{ rotate: `+35deg` }] }]}
                                        source={card8}
                                    />
                                </View>
                            </View>
                        </View>
                        <View style={{ alignContent: 'space-between', alignItems: 'center', flex: 1, flexDirection: 'column' }}>
                            <View style={{ marginBottom: 15 }}>
                                <Text>Play</Text>
                            </View>
                            <View style={{ position: 'relative', alignItems: 'center', marginBottom: 150 }}>
                                <View style={styles.secondStack}>
                                    <Image
                                        style={[styles.cardImage]}
                                        source={card5}
                                    />
                                </View>
                                <View style={styles.secondStack}>
                                    <Image
                                        style={[styles.cardImage, { transform: [{ rotate: `+15deg` }] }]}
                                        source={card5}
                                    />
                                </View>
                                <View style={styles.secondStack}>
                                    <Image
                                        style={[styles.cardImage, { transform: [{ rotate: `+35deg` }] }]}
                                        source={card5}
                                    />
                                </View>
                            </View>
                            <View style={{ marginBottom: 30 }}>
                                <Text>Or</Text>
                            </View>
                            <View style={{ marginBottom: 15 }}>
                                <Text>Play</Text>
                            </View>
                            <View style={{ position: 'relative', alignItems: 'center' }}>
                                <View style={styles.thirdStack}>
                                    <Image
                                        style={[styles.cardImage]}
                                        source={card5}
                                    />
                                </View>
                                <View style={styles.thirdStack}>
                                    <Image
                                        style={[styles.cardImage, { transform: [{ rotate: `+15deg` }] }]}
                                        source={card5}
                                    />
                                </View>
                                <View style={styles.thirdStack}>
                                    <Image
                                        style={[styles.cardImage, { transform: [{ rotate: `+35deg` }] }]}
                                        source={card13}
                                    />
                                </View>
                            </View>
                        </View>
                    </View>
                    <Text style={[styles.mediumText, { marginTop: 10 }]}>
                        Each player will also have the option to pass on their turn if they cannot beat
                        the cards on the stack. Once all players pass, the person who last played will clear the stack and play another set of cards
                        with a rank of their choosing.
                    </Text>
                    <Text style={[styles.leftText, styles.margin]}>
                        Taxes:
                    </Text>
                    <Text style={styles.mediumText}>
                        Once all players empty their hand, a new round begins. At the start of the new round, there is a tax session. The highest ranking players (Emperor and Noble)
                        get to receive the two best cards and the best card from the lowest ranking players (Beggar and Farmer). The two lowest ranking players receive any two cards or one card from the highest ranking players.
                    </Text>
                    <Text style={[styles.mediumText, { marginTop: 10 }]}>
                        Emperor: Receives 2 best cards, gives any 2 cards
                    </Text>
                    <Text style={[styles.mediumText]}>
                        Noble: Receives best card, gives any card
                    </Text>
                    <Text style={[styles.mediumText]}>
                        Farmer: Recevies any card, gives best card
                    </Text>
                    <Text style={[styles.mediumText]}>
                        Beggar: Receives any 2 cards, gives 2 best cards
                    </Text>
                    <Text style={[styles.mediumText, { marginTop: 10 }]}>
                        Once all taxes are paid, a new round begins!
                    </Text>

                    <View style={[{ marginTop: 20, flexDirection: 'row' }]}>
                        <Button color="#fafad2" buttonStyleOverrides={[{ width: 190 }]} onPress={() => { }} primary text="Rebellion!" />
                        <Text style={[styles.mediumText, { flex: 1, marginLeft: 20, flexWrap: 'wrap' }]}>
                            At the beginning of the tax session, if the Beggar ends up with the two wild cards (13) in their
                            hand, they can declare a rebellion! A rebellion means there will be no taxes collected
                            AND every player will flip their social hiearchy. Blessed are thee who are impoverished!
                        </Text>
                    </View>
                    <View style={[{ marginTop: 20, flexDirection: 'row' }]}>
                        <Button color="#fafad2" buttonStyleOverrides={[{ width: 190 }]} onPress={() => { }} primary text="No Taxes!" />
                        <Text style={[styles.mediumText, { flex: 1, marginLeft: 20, flexWrap: 'wrap' }]}>
                            At the beginning of the tax session, if anyone else ends up with the two wild cards (13) in their
                            hand, they can declare no taxes! There will be no taxes collected for this round.
                        </Text>
                    </View>
                    <View style={[styles.bigMargin]}>
                        <View style={[styles.flexRow]}>
                            <Text style={[styles.mediumText, styles.leftMargin]}>
                                NOTE: In the game lobby, as lobby host you can re-arrange the order of the lobby to dictate how the first round begins.
                                When there are 4 or more players in the lobby, you can also begin a game with a tax session by selecting the appropriate
                                check box.
                            </Text>
                        </View>
                        <View style={[styles.margin, styles.flexRow]}>
                            <Avatar containerStyle={[styles.avatarConnected]} size={30} rounded title="A" />
                            <Text style={[styles.mediumText, styles.leftMargin]}>
                                Connected player
                            </Text>
                        </View>
                        <View style={[styles.margin, styles.flexRow]}>
                            <Avatar containerStyle={[styles.avatarConnected]} avatarStyle={styles.avatarBot} size={30} rounded title="A" />
                            <Text style={[styles.mediumText, styles.leftMargin, { flex: 1, flexWrap: 'wrap' }]}>
                                Bot player
                            </Text>
                        </View>
                        <View style={[styles.margin, styles.flexRow]}>
                            <Avatar containerStyle={[styles.avatarDisconnected]} size={30} rounded title="A" />
                            <Text style={[styles.mediumText, styles.leftMargin]}>
                                Disconnected player
                            </Text>
                        </View>
                        <View style={[styles.margin, styles.flexRow]}>
                            <Avatar containerStyle={[styles.avatarSuccess]} size={30} rounded title="A" />
                            <Text style={[styles.mediumText, styles.leftMargin]}>
                                Player finished hand
                            </Text>
                        </View>
                        <View style={[styles.margin, styles.flexRow]}>
                            <Avatar containerStyle={[styles.avatarLeftGame]} size={30} rounded title="A" />
                            <Text style={[styles.mediumText, styles.leftMargin]}>
                                Player left game
                            </Text>
                        </View>
                        <View style={[styles.margin, styles.flexRow]}>
                            <Avatar containerStyle={[styles.avatarSpectator]} size={30} rounded title="A" />
                            <Text style={[styles.mediumText, styles.leftMargin, { flex: 1, flexWrap: 'wrap' }]}>
                                Player spectating game, will be added in the next round
                            </Text>
                        </View>
                    </View>
                </View>
            </ScrollView>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: BACKGROUND_COLOR
    },
    scrollView: {
        marginHorizontal: 20,
    },
    centerContainer: {
        flex: 1
    },
    leftContainer: {
        flex: 1
    },
    margin: {
        marginTop: 20
    },
    bigMargin: {
        marginTop: 40
    },
    leftMargin: {
        marginLeft: 10
    },
    flexRow: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    firstStack: {
        position: 'absolute',
        top: 0
    },
    secondStack: {
        position: 'absolute',
    },
    thirdStack: {
        position: 'absolute',
    },
    leftText: {
        fontSize: 24,
        color: 'black',
    },
    mediumText: {
        fontSize: 18
    },
    avatarConnected: {
        backgroundColor: 'black'
    },
    avatarDisconnected: {
        backgroundColor: DISCONNECTED_COLOR
    },
    avatarSuccess: {
        backgroundColor: 'green'
    },
    avatarSpectator: {
        backgroundColor: 'orange'
    },
    avatarLeftGame: {
        backgroundColor: 'red'
    },
    avatarBot: {
        borderRadius: 30,
        borderWidth: 2,
        borderColor: 'yellow'
    },
    cardImage: {
        height: 146.5 * .75,
        width: 91 * .75
    },
    text: {
        fontSize: 12,
        fontStyle: 'italic',
        color: 'black',
        textAlign: 'center'
    },
    pushRight: {
        color: 'black',
        paddingLeft: 120
    }
});

export default RulesPage;