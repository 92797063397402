import React from 'react';
import { StyleSheet, View, Text, TouchableOpacity, Image, Platform } from 'react-native';
import axios from 'axios';
import { LOBBY_SERVICE_URL, GAME_TYPE } from "../../constants";
import { BACKGROUND_COLOR } from "../../styles/styles";
import LobbyServiceContext from '../../services/lobby-service';
import TelemetryServiceContext from "../../services/telemetry-service";
import { Button } from "../button/button";
import { Player } from "../../data/interfaces";
import { readPlayerFromStorage } from "../../services/player-service";
import { useMyDeviceOrientation } from "../../services/device-orientation";
import ExpoConstants from 'expo-constants';
import {
   Menu,
   MenuOptions,
   MenuOption,
   MenuTrigger,
} from 'react-native-popup-menu';

interface IHomePageProps {
   navigation: any
}

export const HomePage = ({ navigation }: IHomePageProps) => {
   const { lobbyService } = React.useContext(LobbyServiceContext);
   const { telemetryService } = React.useContext(TelemetryServiceContext);
   const landscape = useMyDeviceOrientation() === "landscape";
   const [hiddenMenuCount, setHiddenMenuCount] = React.useState(0);
   const [player, setPlayer] = React.useState({} as any);

   React.useEffect(() => {
      const unsubscribe = navigation.addListener('focus', () => {
         setHiddenMenuCount(0);
         readPlayerFromStorage().then(p => {
            console.log(`Read ${p && p.displayName} from storage`);
            setPlayer(p);
            telemetryService && telemetryService.trackEvent({ name: 'home_page_view', properties: { playerId: p && p.id, displayName: p && p.displayName, clientVersion: ExpoConstants && ExpoConstants.manifest && ExpoConstants.manifest.version, deviceId: ExpoConstants && ExpoConstants.deviceId } });
         }).catch(error => {
            console.error(`Error reading player from storage: ${error}`);
            telemetryService && telemetryService.trackEvent({ name: 'home_page_view', properties: { playerId: undefined, displayName: undefined, clientVersion: ExpoConstants && ExpoConstants.manifest && ExpoConstants.manifest.version, deviceId: ExpoConstants && ExpoConstants.deviceId } });
         });
      });

      return unsubscribe;
   }, [navigation]);

   React.useLayoutEffect(() => {
      navigation.setOptions({
         headerRight: () => (
            <View style={{ paddingRight: 10 }}>
               {optionsMenu()}
            </View>
         ),
      });
   }, [navigation]);

   const onSelectMenuItem = (value: number) => {
      switch (value) {
         case 1:
            console.log("Navigating to rules page");
            navigation.navigate("RulesPage", {});
            break;
         case 2:
            console.log('Navigating to new player page');
            navigation.navigate("NewPlayer", { isChangeName: true });
            break;
         default:
            console.log(`Value ${value} is not handled yet`);
            break;
      }
   };

   const optionsMenu = () => {
      return (
         <Menu onSelect={value => onSelectMenuItem(value)}>
            <MenuTrigger>
               <Image
                  style={styles.moreOptionsIcon}
                  source={require('../../assets/icons/ellipsis-vertical-icon.png')}></Image>
            </MenuTrigger>
            <MenuOptions customStyles={{ optionsContainer: styles.moreOptionsContainer }}>
               <View style={{ borderColor: 'white', borderBottomWidth: 0 }}>
                  <MenuOption style={styles.menuOption} value={1}>
                     <Image
                        style={styles.menuOptionIcon}
                        source={require('../../assets/icons/dice-icon.png')}></Image>
                     <Text style={{ color: 'white', paddingTop: 0, paddingLeft: 10 }}>How to play</Text>
                  </MenuOption>
               </View>
               <View>
                  <MenuOption style={styles.menuOption} value={2}>
                     <Image
                        style={styles.menuOptionIcon}
                        source={require('../../assets/icons/wand-icon.png')}></Image>
                     <Text style={{ color: 'white', paddingTop: 0, paddingBottom: 4, paddingLeft: 10 }}>Change name</Text>
                  </MenuOption>
               </View>
            </MenuOptions>
         </Menu>
      );
   }

   const renderAppInfo = () => {
      if (ExpoConstants && ExpoConstants.manifest) {
         let version = ExpoConstants.manifest.version;
         return (
            <View style={styles.appInfoContainer}>
               {version && <Text selectable={true}>Version: {version} </Text>}
            </View>
         );
      }
      return null;
   };

   const renderVerticalButtons = () => {
      return (
         <View style={styles.buttonsContainerVertical}>
            <Button primary onPress={() => onCreateButtonPress(navigation, lobbyService, setHiddenMenuCount, player)} text="Create"></Button>
            <Button secondary buttonStyleOverrides={styles.buttonPaddingVertical} onPress={() => onJoinButtonPress(navigation, setHiddenMenuCount, player)} text="Join"></Button>
         </View>);
   };

   const renderHorizontalButtons = () => {
      return (
         <View style={styles.buttonsContainerHorizontal}>
            <Button primary buttonStyleOverrides={styles.buttonPaddingHorizontalRight} onPress={() => onCreateButtonPress(navigation, lobbyService, setHiddenMenuCount, player)} text="Create"></Button>
            <Button secondary buttonStyleOverrides={styles.buttonPaddingHorizontalLeft} onPress={() => onJoinButtonPress(navigation, setHiddenMenuCount, player)} text="Join"></Button>
         </View>);
   };

   return (
      <View style={styles.background}>
         <View style={[landscape ? styles.logoContainerLandscape : styles.logoContainerVertical]}>
            <TouchableOpacity activeOpacity={1} onPress={() => setHiddenMenuCount(hiddenMenuCount + 1)}>
               <View style={[styles.logoShadow, styles.logo]}>
                  <Image
                     style={styles.logo}
                     source={require('../../assets/icons/emperors-logo.png')}
                  />
               </View>
            </TouchableOpacity>
            {hiddenMenuCount >= 5 && renderAppInfo()}
            <Image
               style={styles.logoText}
               source={require('../../assets/icons/emperors-txt.png')}
            />
         </View>

         {(!landscape || Platform.OS === 'web') ? renderVerticalButtons() : renderHorizontalButtons()}
      </View>
   );
}


const onCreateButtonPress = async (navigation: any, lobbyService: any, setHiddenMenuCount: any, player?: any) => {
   if (player && player.id) {
      console.log("Creating a new game");
      createGameAndNavigateToLobby(navigation, lobbyService, player);
   } else {
      console.log('Navigating to new player page');
      navigation.navigate("NewPlayer", { isLobbyOwner: true });
   }
   setHiddenMenuCount(0);
}

const createGameAndNavigateToLobby = (navigation: any, lobbyService: any, player: Player) => {
   axios.post(`${LOBBY_SERVICE_URL}/api/game`, {
      gameType: GAME_TYPE.Dalmuti
   }).then(response => {
      const game = response.data;
      console.log(`Game with joinCode ${game.joinCode} created`);
      lobbyService.emit("joinGame", player, game.joinCode);
      navigation.navigate("Lobby", { joinCode: game.joinCode, isLobbyOwner: true });
   }).catch(error => {
      console.error(error);
   });
}

const onJoinButtonPress = async (navigation: any, setHiddenMenuCount: any, player?: any) => {
   if (player && player.id) {
      console.log("Navigating to join code page.");
      navigation.navigate("JoinCode", { isLobbyOwner: false, player });
   } else {
      console.log('Navigating to new player page');
      navigation.navigate("NewPlayer", { isLobbyOwner: false });
   }
   setHiddenMenuCount(0);
}

const styles = StyleSheet.create({
   background: {
      flex: 1,
      backgroundColor: BACKGROUND_COLOR,
      alignItems: 'center',
      justifyContent: 'center',
   },
   logoContainerLandscape: {
      paddingBottom: 100,
      alignItems: 'center'
   },
   logoContainerVertical: {
      paddingBottom: 200,
      alignItems: 'center'
   },
   appInfoContainer: {
      alignItems: 'center',
      marginTop: 5
   },
   logoText: {
      height: 50,
      width: 180,
      marginTop: 20
   },
   logo: {
      width: 220,
      height: 210,
      borderRadius: 40
   },
   logoShadow: {
      marginBottom: 5,
      shadowColor: 'black',
      shadowOpacity: 0.8,
      shadowRadius: 9,
      shadowOffset: { width: 2, height: 14 }
   },
   moreOptionsIcon: {
      width: 30,
      height: 30
   },
   moreOptionsContainer: {
      backgroundColor: 'black',
      borderRadius: 6,
      width: 200,
      height: 75,
      marginTop: 35,
      marginLeft: 20
   },
   menuOption: {
      flexDirection: 'row',
      alignItems: 'center',
      width: 'auto'
   },
   menuOptionIcon: {
      width: 25,
      height: 25
   },
   buttonsContainerVertical: {
      flex: 1,
      position: 'absolute',
      justifyContent: 'space-evenly',
      bottom: 70,
      maxHeight: 300
   },
   buttonsContainerHorizontal: {
      flex: 1,
      justifyContent: 'space-evenly',
      flexDirection: 'row',
      position: 'absolute',
      bottom: 30
   },
   buttonPaddingVertical: {
      marginTop: 20
   },
   buttonPaddingHorizontalRight: {
      marginRight: 10
   },
   buttonPaddingHorizontalLeft: {
      marginLeft: 10
   }
});
export default HomePage;