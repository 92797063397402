import React from 'react';
import { Platform, TouchableWithoutFeedback, Keyboard } from 'react-native';
interface IInputContainer {
    children: any;
}

export const InputContainer = ({ children }: IInputContainer) => {
    if (Platform.OS == 'web') {
        return (<>{children}</>);
    }
    return (
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            {children}
        </TouchableWithoutFeedback>
    );
};

export default InputContainer;